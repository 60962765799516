import { Login } from "../pages/auth/login";
import LayoutRoot from "../components/layout";
import { GuardPublicRoutes } from "./GuardPublicRoutes";
import { GuardPrivateRoutes } from "./GuardPrivateRoutes";
import { pagesList } from "../constants/application/pages";
import { Navigate, Route, Routes as Switch } from "react-router-dom";

export const Routes = () => {
  return (
    <Switch>
      <Route path="p" element={<GuardPublicRoutes />}>
        <Route path="login" element={<Login />} />
        <Route path="" element={<Navigate to="login" />} />
        <Route path="*" element={<Navigate to="login" />} />
      </Route>

      <Route path="/" element={<GuardPrivateRoutes />}>
        {pagesList
          .map(({ Element, path, key }) => (
            <Route
              key={key}
              path={path}
              element={
                <LayoutRoot >
                  <Element />
                </LayoutRoot>
              }
            />
          ))}
        <Route path="*" element={<Navigate to="" />} />
      </Route>
    </Switch>
  );
};
