import { Container, ErrorCode, ErrorContentBox, ErrorHeaderBox, ErrorIcon } from "./style";
import { SiAdblock } from "react-icons/si";

const AccessDenied: React.FC = () => {
  return (
    <Container>
      <ErrorHeaderBox>
        <ErrorCode>
          <h1>401</h1>
          <h4>Acesso não autorizado</h4>
        </ErrorCode>
        <ErrorIcon>
          <SiAdblock size={128} />
        </ErrorIcon>
      </ErrorHeaderBox>
      <ErrorContentBox>
        <h2>Seu perfil não possui permissão para acessar este recurso.</h2>
      </ErrorContentBox>
    </Container>
  );
};

export default AccessDenied;
