import { Button, Col, Flex, message, Row, Table } from "antd";
import { Container } from "../../components/container";
import { useEffect, useState } from "react";
import { api } from "../../api/api";
import { ColumnsType } from "antd/es/table";
import { SearchForm } from "../../forms/parameters/SearchForm";
import { GrRefresh } from "react-icons/gr";
import { application } from "../../constants/application/app";
import { useSecurity } from "../../hooks/useSecurity";
import { PageHeader } from "../../components/page-header";
import { ActionGrid } from "../../components/action-grid";

export const Parameters: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [parameters, setParameterss] = useState<any[]>([]);
  const [listMenus, setListMenus] = useState<any[]>([]);
  const [roles, setRoles] = useState<any>();
  const { getPermissions } = useSecurity();

  const getData = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/routes`)))
      .then(({ data }: any) => {
        setParameterss(data);
        setListMenus(
          Object.values(data)
            .flat()
            .filter((e:any)=>!e.isSubMenu)
            .map((item: any) => ({
              text: item.title,
              value: item.title,
            }))
        );
      
      })
      .finally(() => setLoading(false));
  };

  const handleRefresh = () => {
    setLoading(true);

    // loop percorendo todos os menus
    const listRoutes:any[] = [];
    for (const routes of application.menu || []) {
      //

      // se nao tiver children, sincroniza as rotas
      listRoutes.push({
        root:(routes as any)?.title,
        title: (routes as any)?.title,
        page: (routes as any)?.title,
        route: `/${routes!.key}`,
        isSubMenu: false,
      });

      // se tiver children, percorre os children
      for (const children of (routes as any)?.children || []) {
        //

        // se nao tiver children, sincroniza as rotas
        listRoutes.push({
          root:(routes as any)?.title,
          title: (children as any)?.title,
          page: `${(children as any)?.title}`,
          route: `/${children!.key}`,
          isSubMenu: true,
        });

        // verifica se tem menus aninhados
        for (const element of children?.children || []) {

          // se nao tiver children, sincroniza as rotas
          listRoutes.push({
            root:(children as any)?.title,
            title: (element as any)?.title,
            page: `${(element as any)?.title}`,
            route: `/${element!.key}`,
            isSubMenu: true,
          });
        }
      }
    }
    new Promise((resolve, _) => resolve(api.post(`/routes`, {routes:listRoutes})))
            .then(()=>message.success("Rotas sincronizadas com sucesso!"))
            .finally(()=>setLoading(false));

    new Promise((resolve, _) => resolve(getData()));
  };

  const onSearch = (content: any) => {
    setLoading(true);

    let body = {};
    for (const key in content || {}) {
      if (content[key] !== undefined) {
        body = {
          ...body,
          [key]: content[key],
        };
      }
    }
    const queryString = new URLSearchParams(body).toString();

    if (String(queryString).length !== 0) {
      new Promise((resolve, _) => resolve(api.get(`/routes?${queryString}`)))
        .then(({ data }: any) => setParameterss(data))
        .finally(() => setLoading(false));
    } else {
      new Promise((resolve, _) => resolve(api.get(`/routes`)))
        .then(({ data }: any) => setParameterss(data))
        .finally(() => setLoading(false));
    }
  };

  const handleDelete = ({ content }: any) => {
    new Promise((resolve, _) =>
      resolve(api.delete(`/routes/${content?._id}`))
    ).then(() => new Promise((resolve, _) => resolve(getData())));
  };

  useEffect(() => {
    new Promise((resolve, _) => resolve(getData()));
  }, []);

  useEffect(() => {
    const role = getPermissions({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissions]);

  const columns: ColumnsType<[]> = [
    {
      title: "Menu Raiz",
      dataIndex: "root",
      key: "root",
      ellipsis: true,
      filters: listMenus,
      onFilter: (value, record: any) => record?.root?.includes(value as string),
    },
    {
      title: "Título",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
    },
    { title: "Página", dataIndex: "page", key: "page", ellipsis: true, },
    { title: "Rota", dataIndex: "route", key: "route", ellipsis: true, },
    {
      render: (value, record) => (
        <ActionGrid
            handleEdit={()=> {}}
            handleDelete={()=>handleDelete({content:value})}
            roles={roles}
            value={value}
          />
      ),
    },
  ];

  return (
    <Container>
      <Row>
        <Col span={24}>
          <PageHeader title="Parâmetros de Rotas" roles={roles} />
          <SearchForm onFinish={onSearch} onClear={getData} />
        </Col>

        <Col span={24}>
          <Flex justify="end">
            <Button
              style={{
                alignContent: "end",
                marginBottom: 10,
              }}
              type="default"
              onClick={handleRefresh}
              icon={<GrRefresh />}
            >
              Sincroniar Rotas
            </Button>
          </Flex>
          <Table
            rowKey={"_id"}
            bordered
            loading={loading}
            dataSource={parameters}
            columns={columns}
            size="small"
            style={{ width: "100%", overflow: "scroll" }}
          />
        </Col>
      </Row>
    </Container>
  );
};
