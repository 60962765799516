import { Button, Col, Form, Input, Row, Spin } from "antd";
import { Container } from "./style";
import { useEffect, useState } from "react";

export const ResetPassword: React.FC<{
  onFinish: (data: any) => void;
  loading: boolean;
}> = ({ onFinish, loading }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  return (
    <Container>
      <Spin tip={"Carregando"} spinning={isLoading} size="large">
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={[8, 2]}>
            <Col span={24}>
              <Form.Item
                name="username"
                label="Email"
                rules={[{ required: true, message: "Email é obrigatório" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="password"
                label="Nova Senha"
                rules={[{ required: true, message: "Senha é obrigatória" }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="confirmPassword"
                label="Confirmar Senha"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Confirme a senha",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("As senhas não coincidem")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={24} style={{ textAlign: "right" }}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Enviar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Container>
  );
};
