import dayjs from "dayjs";
import { Container } from "./style";
import { api } from "../../api/api";
import { useEffect, useState } from "react";
import { Col, Row, Spin, Table } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useSecurity } from "../../hooks/useSecurity";
import { PageHeader } from "../../components/page-header";
import { SearchForm } from "../../forms/cashFlow/SearchForm";
import {
  ColumnsTable,
  EditFormCashFlow,
  TableSummary,
} from "../../constants/pages/cashFlow";
import { useDrawer } from "../../hooks/useDrawer";

const CashFlow: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [cashFlows, setCashFlows] = useState<any[]>([]);
  const [financialCategories, setFinancialCategories] = useState<any[]>([]);
  const [roles, setRoles] = useState<any>();
  const [params, setParams] = useState<any>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });

  const [form] = useForm();
  const { getPermissions } = useSecurity();
  const { setTitle, setClose, setIsOpen, setContent, setWidth } = useDrawer();

  const getCashFlow = (filter?: any) => {
    new Promise((resolve, _) =>
      resolve(
        api.get(`/cash-flow`, {
          params: filter,
        })
      )
    )
      .then(({ data }: any) => setCashFlows(data))
      .finally(() => setLoading(false));
  };

  const getFinancialCategory = (filter?: any) => {
    new Promise((resolve, _) => resolve(api.get(`/financialCategories`)))
      .then(({ data }: any) => setFinancialCategories(data))
      .finally(() => setLoading(false));
  };

  const onFinish = (content: any) => {
    const { competencePayment, financialCategory } = content;
    new Promise((resolve, _) => {
      if (content?._id) {
        resolve(
          api.put(`/cash-flow/${content?._id}`, {
            financialCategory,
            ...(competencePayment
              ? {
                  competenceMonth: dayjs(competencePayment).month() + 1,
                  competenceYear: dayjs(competencePayment).year(),
                }
              : {}),
          })
        );
      }
    }).then(() => {
      new Promise((resolve, _) => resolve(getCashFlow()));
      setIsOpen(false);
    });
  };

  const handleEdit = (data: any) => {
    setIsOpen!(true);
    setTitle("Editar Fluxo de Caixa");
    setContent!(
      <EditFormCashFlow
        data={{ cashFlow: data, financialCategories }}
        onFinish={onFinish}
      />
    );
    setWidth!(600);
  };

  const onSearch = () => {
    setParams((prev: any) => ({ ...prev, ...form.getFieldsValue() }));
  };

  const onReset = () => {
    setLoading(true);
    form.resetFields();
    setParams({
      startDate: "",
      endDate: "",
    });
    setLoading(false);
  };

  useEffect(() => {
    new Promise((resolve, _) => resolve(getFinancialCategory()));
    // new Promise((resolve, _) => resolve(getCashFlow(params)));
  }, []);

  useEffect(() => {
    getCashFlow(params);
  }, [params]);

  useEffect(() => {
    const role = getPermissions({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissions]);

  return (
    <Container>
      {/* <Spin tip={"Carregando..."} spinning={loading}> */}
        <Row>
          <Col span={24}>
            <PageHeader
              title="Fluxo de Caixa"
              roles={{
                ...roles,
                create: false,
              }}
            />
            <SearchForm
              form={form}
              onFinish={onSearch}
              onReset={onReset}
              data={{}}
            />
          </Col>
          <Col span={24}>
            <Table
              bordered
              rowKey={"_id"}
              size="small"
              columns={ColumnsTable({
                handleEdit: handleEdit,
              })}
              dataSource={cashFlows}
              summary={(data:any) => TableSummary({
                dataSource: data,
              })}
            />
          </Col>
        </Row>
      {/* </Spin> */}
    </Container>
  );
};
export default CashFlow;
