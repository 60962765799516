import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const GuardPublicRoutes = () => {
  const { user, loadingSession } = useAuth();

  if (loadingSession) {
    return <></>;
  }

  return !user ? <Outlet /> : <Navigate to="/" />;
};
