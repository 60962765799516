import { Button, Col, Flex, Form, Input, Row, Select, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { DatePicker } from "antd/lib";
import { useEffect, useState } from "react";
import { EStatusPayment } from "../../enum/EStatusPayment";

type SearchFormProp = {
  onFinish?: (values: any) => void;
  onReset?: () => void;
  data: any;
};
export const SearchForm: React.FC<SearchFormProp> = ({
  onFinish,
  onReset,
  data,
}) => {
  const [form] = useForm();
  const [financialCategories, setFinancialCategories] = useState<any[]>([]);

  const handleSubmit = () => onFinish!(form.getFieldsValue());
  const handleReset = () => {
    form.resetFields();
    onReset!();
  };

  useEffect(() => {
    setFinancialCategories(data?.financialCategories);
  }, [data]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      style={{ minWidth: "100%" }}
    >
      <Row gutter={[8, 2]}>
        <Col span={8}>
          <Form.Item label="Competência" name={"competence"}>
            <DatePicker
              picker="month"
              style={{ width: "100%" }}
              format={"MM/YYYY"}
              placeholder="Competência"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Classificação Financeira"
            name={"financialCategory"}
          >
            <Select
              allowClear
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={financialCategories?.flatMap((e: any) => [
                { value: e?._id, label: e?.description },
              ])}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[8, 2]} align={"middle"}>
        <Col span={8}>
          <Form.Item label="Emissão" name={"startIssueDate"}>
            <DatePicker
              placeholder="Data Início"
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Typography.Text type="secondary">Até</Typography.Text>
        </Col>
        <Col span={8}>
          <Form.Item label=" " name={"endIssueDate"}>
            <DatePicker
              placeholder="Data Fim"
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[8, 2]} align={"middle"}>
        <Col span={8}>
          <Form.Item label="Vencimento" name={"startDueDate"}>
            <DatePicker
              placeholder="Data Início"
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Typography.Text type="secondary">Até</Typography.Text>
        </Col>
        <Col span={8}>
          <Form.Item label=" " name={"endDueDate"}>
            <DatePicker
              placeholder="Data Fim"
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 2]} align={"middle"}>
        <Col span={8}>
          <Form.Item label="Pagamento" name={"startPaymentDate"}>
            <DatePicker
              placeholder="Data Início"
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Typography.Text type="secondary">Até</Typography.Text>
        </Col>
        <Col span={8}>
          <Form.Item label=" " name={"endPaymentDate"}>
            <DatePicker
              placeholder="Data Fim"
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[8, 2]}>
        <Col span={8}>
          <Form.Item label="Status Pagamento" name={"status"}>
            <Select
              placeholder="Status Pagamento"
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={Object.keys(EStatusPayment).map((e: any) => ({
                label: EStatusPayment[e],
                value: e,
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item>
            <Flex gap={2} justify="end">
              <Button type="default" icon={<ClearOutlined />} onClick={handleReset}>
                Limpar
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                Pesquisar
              </Button>
            </Flex>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
