import _ from "lodash";
import dayjs from "dayjs";
import { api } from "../../api/api";
import { useEffect, useState } from "react";
import {  Title } from "./style";
import { Card } from "../../components/card";
import { useDrawer } from "../../hooks/useDrawer";
import { Col, Row, Statistic, Table } from "antd";
import { NumberOutlined } from "@ant-design/icons";
import { useSecurity } from "../../hooks/useSecurity";
import { PageHeader } from "../../components/page-header";
import { SearchForm } from "../../forms/accountsPayable/SearchForm";
import { ColumnsTable, TableSummary } from "../../constants/pages/accountsPayable";
import { CreateOrEditForm } from "../../forms/accountsPayable/CreateOrEditForm";
import { Container } from "../../components/container";

const AccountsPayable: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [roles, setRoles] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [accountPayable, setAccountPayable] = useState<any>();
  const [paymentTypes, setPaymentTypes] = useState<any[]>([]);
  const [documentTypes, setDocumentTypes] = useState<any[]>([]);
  const [accountPayables, setAccountPayables] = useState<any[]>([]);
  const [financialCategories, setFinancialCategories] = useState<any[]>([]);
  const [params, setParams] = useState<any>({
    competence: "",
    financialCategory: "",
    startIssueDate: "",
    endIssueDate: "",
    startDueDate: "",
    endDueDate: "",
    status: "",
  });

  const { getPermissions } = useSecurity();
  const { setIsOpen, setTitle, setContent, setClose } = useDrawer();

  const getData = (filter?: any) => {
    setLoading(true);
    new Promise((resolve, _) =>
      resolve(
        api.get(`/accounts-payable`, {
          params: filter,
        })
      )
    )
      .then(({ data }: any) => {
        setAccountPayables(data);
      })
      .finally(() => setLoading(false));
  };

  const getSuppliers = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/supplier`)))
      .then(({ data }: any) => {
        setSuppliers(data);
      })
      .finally(() => setLoading(false));
  };

  const getDocumentTypes = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/paymentTypes`)))
      .then(({ data }: any) => {
        setPaymentTypes(data);
      })
      .finally(() => setLoading(false));
  };

  const getPaymentTypes = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/documentTypes`)))
      .then(({ data }: any) => {
        setDocumentTypes(data);
      })
      .finally(() => setLoading(false));
  };

  const getFinancialCategories = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/financialCategories`)))
      .then(({ data }: any) => {
        setFinancialCategories(data);
      })
      .finally(() => setLoading(false));
  };

  const handleAdd = () => {
    setIsOpen(true);
    setTitle("Adicionar");
    setContent(
      <CreateOrEditForm
        onFinish={onSubmit}
        data={{
          accountPayable,
          suppliers,
          documentTypes,
          paymentTypes,
          financialCategories,
        }}
      />
    );
  };

  const handleEdit = ({ data }: { data?: any }) => {
    setIsOpen(true);
    setTitle("Editar");
    setContent(
      <CreateOrEditForm
        onFinish={onSubmit}
        data={{
          accountPayable: data,
          suppliers,
          documentTypes,
          paymentTypes,
          financialCategories,
        }}
      />
    );
  };

  const handleDelete = ({ content }: any) => {
    new Promise((resolve, _) =>
      resolve(api.delete(`/accounts-payable/${content?._id}`))
    ).then(() => new Promise((resolve, _) => resolve(getData())));
  };

  const onSearch = (data: any) => {
    const serialized = {
      ...(data?.competence
        ? { competence: dayjs(data?.competence).format("MM/YYYY") }
        : {}),
      ...(data?.startIssueDate
        ? { startIssueDate: dayjs(data?.startIssueDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.endIssueDate
        ? { endIssueDate: dayjs(data?.endIssueDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.startDueDate
        ? { startDueDate: dayjs(data?.startDueDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.endDueDate
        ? { endDueDate: dayjs(data?.endDueDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.startPaymentDate
        ? { startPaymentDate: dayjs(data?.startPaymentDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.endPaymentDate
        ? { endPaymentDate: dayjs(data?.endPaymentDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.status ? { status: data?.status } : {}),
      ...(data?.financialCategory
        ? { financialCategory: data?.financialCategory }
        : {}),
    };
    setParams((prev: any) => ({ ...prev, serialized }));
    getData(serialized);
  };

  const onReset = () => {
    setLoading(true);
    setParams({
      competence: "",
      financialCategory: "",
      startIssueDate: "",
      endIssueDate: "",
      startDueDate: "",
      endDueDate: "",
      status: "",
    });
    setLoading(false);
    getData();
  };

  const onSubmit = (content: any) => {
    new Promise((resolve, _) => {
      if (content?._id) {
        resolve(
          api.put(`/accounts-payable/${content?._id}`, {
            ...content,
            ...(content?.competence
              ? {
                  competenceMonth: dayjs(content?.competence).month() + 1,
                  competenceYear: dayjs(content?.competence).year(),
                }
              : {}),
            amount: String(content?.amount)
              .replace("R$", "")
              .replace(".", "")
              .replace(",", "."),
          })
        );
      } else {
        resolve(
          api.post(`/accounts-payable`, {
            ...content,
            ...(content?.competence
              ? {
                  competenceMonth: dayjs(content?.competence).month() + 1,
                  competenceYear: dayjs(content?.competence).year(),
                }
              : {}),
            amount: String(content?.amount)
              .replace("R$", "")
              .replace(".", "")
              .replace(",", "."),
          })
        );
      }
    })
      .then(() => {
        new Promise((resolve, _) => resolve(getData()));
        setAccountPayable({});
        setOpen(false);
      })
      .finally(() => {
        setLoading(false);
        setClose(true);
      });
  };

  useEffect(() => {
    new Promise((resolve, _) => resolve(getDocumentTypes()));
    new Promise((resolve, _) => resolve(getFinancialCategories()));
    new Promise((resolve, _) => resolve(getPaymentTypes()));
    new Promise((resolve, _) => resolve(getSuppliers()));
    new Promise((resolve, _) => resolve(getData()));
  }, []);

  useEffect(() => {
    const role = getPermissions({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissions]);

  return (
    <Container>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <PageHeader
            title="Contas A Pagar"
            roles={roles}
            onClickAdd={handleAdd}
          />
        </Col>

        <Col span={24}>
          <SearchForm
            onFinish={onSearch}
            onReset={onReset}
            data={{ financialCategories, suppliers }}
          />
        </Col>

        <Col span={12}>
          <Card color={200}>
            <Statistic
              title={<Title>Qtd Títulos a Pagar</Title>}
              value={(accountPayables || [])?.length}
              precision={0}
              valueStyle={{ color: "var(--codar-bg-600)" }}
              prefix={
                <NumberOutlined style={{ color: "var(--codar-bg-600)" }} />
              }
            />
          </Card>
        </Col>

        <Col span={12}>
          <Card color={200}>
            <Statistic
              title={<Title>Valor a Pagar</Title>}
              value={new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(
                (accountPayables || []).reduce(
                  (prev, curr) => prev + Number(curr?.amount || 0),
                  0
                )
              )}
              precision={0}
              valueStyle={{ color: "var(--codar-bg-600)" }}
            />
          </Card>
        </Col>

        <Col span={24}>
          <Table
            loading={loading}
            bordered
            rowKey={"_id"}
            size="small"
            columns={ColumnsTable({
              onSubmit,
              handleDelete,
              handleEdit,
              roles,
            })}
            dataSource={accountPayables}
            summary={()=>TableSummary({dataSource:accountPayables})}
          />
        </Col>
      </Row>
    </Container>
  );
};
export default AccountsPayable;
