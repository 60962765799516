import React, { useRef } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import HC_exporting from "highcharts/modules/exporting";
// HC_exporting(Highcharts);

type ColumnChartProp = {
  title?: string;
  data?: any;
  categories?: any;
  drilldown?: any;
};
const ColumnChart: React.FC<ColumnChartProp> = (
  { title, data, categories },
  props
) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const options: Highcharts.Options = {
    chart: {
      type: "column",
    },
    title: {
      text: title,
    },
    xAxis: {
      categories,
      crosshair: true,
      accessibility: {
        description: "Meses",
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format((this as any)?.y);
          },
        },
      },
    },
    tooltip: {
      pointFormatter: function () {
        return (
          '<span style="color:' +
          this.color +
          '">\u25CF</span> ' +
          this.series.name +
          ": <b>" +
          new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format((this as any).y) +
          "</b><br/>"
        );
      },
    },
    
    series: data,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
      {...props}
    />
  );
};

export default ColumnChart;
