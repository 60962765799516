import dayjs from "dayjs";
import { Table, Tag } from "antd";
import { ActionGrid } from "../../components/action-grid";

interface DetailsGridProps {
  loading: boolean;
  dataSource: any;
  handleEdit: (data: any) => void;
  handleDelete: (data: any) => void;
}
export const DetailsGrid: React.FC<DetailsGridProps> = ({
  handleEdit,
  handleDelete,
  dataSource,
  loading
}) => {
  return (
    <Table
    loading={loading}
      bordered
      rowKey={"_id"}
      size="small"
      columns={[
        {
          title: "Data",
          dataIndex: "date",
          key: "date",
          render: (value) => (
            <Tag>
              {dayjs(value).format("DD/MM/YYYY")}
            </Tag>
          ),
        },
        {
          title: "Fornecedor",
          dataIndex: "supplier",
          key: "supplier",
          width: "85%",
          render: (value) => (
            <Tag>
              {value?.name}
            </Tag>
          ),
        },
        {
          title: "Valor",
          dataIndex: "value",
          key: "value",
          width: "15%",
          render: (value) => (
            <Tag>
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)}
            </Tag>
          ),
        },
        {
          title: "#",
          align: "center",
          render: (value, record) => (
            <ActionGrid
              onlyIcon={true}
              handleEdit={() => handleEdit(value)}
              handleDelete={() => handleDelete(value)}
              roles={{ create: true, edit: true, delete: true, show: true }}
              value={value}
            />
          ),
        },
      ]}
      dataSource={dataSource}
      pagination={false}
      summary={(data) => {
        return (
          <>
            <Table.Summary fixed={"bottom"}>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} align="end"/>
                <Table.Summary.Cell index={1} align="end">
                  <b style={{ fontSize: 10 }}>Total:</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="start">
                  {
                    <Tag color="blue">
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(
                        (data || []).reduce(
                          (prev, curr?: any) => prev + Number(curr?.value || 0),
                          0
                        )
                      )}
                    </Tag>
                  }
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          </>
        );
      }}
    />
  );
};
