import { Button, Col, Divider, Row, Table, Tooltip } from "antd";
import {  GroupButton } from "./style";
import { Container } from "../../components/container";
import { useEffect, useState } from "react";
import { api } from "../../api/api";
import { ColumnsType } from "antd/es/table";
import { red } from "@ant-design/colors";
import Drawer from "../../components/drawer";
import { SearchForm } from "../../forms/categories/SearchForm";
import { CreateOrEditForm } from "../../forms/categories/CreateOrEditForm";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

export const Categories: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [category, setCategory] = useState({});
  const [categories, setCategories] = useState<any[]>([]);

  const getData = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/categories`)))
      .then(({ data }: any) => setCategories(data))
      .finally(() => setLoading(false));
  };

  const handleAdd = () => {
    setAction("add");
    setCategory({});
    setOpen(true);
  };

  const handleEdit = ({ data }: any) => {
    setAction("edit");
    setCategory(data);
    setOpen(true);
  };

  const handleDelete = ({ content }: any) => {
    new Promise((resolve, _) =>
      resolve(api.delete(`/categories/${content?._id}`))
    ).then(() => new Promise((resolve, _) => resolve(getData())));
  };

  const onSubmit = (content: any) => {
    new Promise((resolve, _) => {
      if (content?._id) {
        resolve(api.put(`/categories/${content?._id}`, content));
      } else {
        resolve(api.post(`/categories`, content));
      }
    }).then(() => {
      new Promise((resolve, _) => resolve(getData()));
      setCategory({});
      setOpen(false);
    });
  };

  const onSearch = (content: any) => {
    setLoading(true);

    let body = {};
    for (const key in content || {}) {
      if (content[key] !== undefined) {
        body = {
          ...body,
          [key]: content[key],
        };
      }
    }
    const queryString = new URLSearchParams(body).toString();

    if (String(queryString).length !== 0) {
      new Promise((resolve, _) =>
        resolve(api.get(`/categories?${queryString}`))
      )
        .then(({ data }: any) => setCategories(data))
        .finally(() => setLoading(false));
    } else {
      new Promise((resolve, _) => resolve(api.get(`/categories`)))
        .then(({ data }: any) => setCategories(data))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    new Promise((resolve, _) => resolve(getData()));
  }, []);

  const columns: ColumnsType<[]> = [
    { title: "Descrição", dataIndex: "description", key: "description" },
    { title: "Situação", dataIndex: "status", key: "status" },
    {
      render: (value, record) => (
        <GroupButton>
          <Tooltip title="Editar">
            <Button
              type="text"
              onClick={() => handleEdit({ data: value })}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Excluir">
            <Button
              onClick={() => handleDelete({ content: value })}
              type="text"
              icon={
                <DeleteOutlined style={{ color: "var(--codar-danger-500)" }} />
              }
            />
          </Tooltip>
        </GroupButton>
      ),
    },
  ];

  return (
    <Container>
      <Row>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 25,
            }}
          >
            <h1>Categorias</h1>
            <Button type="primary" onClick={handleAdd} icon={<PlusOutlined />}>
              Novo
            </Button>
          </div>
          <Divider dashed />
          <SearchForm onFinish={onSearch} />
        </Col>

        <Col span={24}>
          <Divider dashed />
        </Col>
        <Col span={24}>
          <Table
            rowKey={"id"}
            bordered
            loading={loading}
            dataSource={categories}
            columns={columns}
            size="small"
            style={{ width: "100%", overflow: "scroll" }}
          />
        </Col>
      </Row>

      <Drawer
        open={open}
        title={action === "add" ? "Adicionar" : "Editar"}
        destroyOnClose={true}
        onClose={() => setOpen(false)}
        children={<CreateOrEditForm onFinish={onSubmit} data={category} />}
      />
    </Container>
  );
};
