import { Button, Form, FormInstance, Input } from "antd";
import { FormContentButtons } from "../../pages/companies/style";
import { useForm } from "antd/lib/form/Form";
import { SearchOutlined } from "@ant-design/icons";
import { Wrapper } from "./style";

type SearchFormProp = {
  onFinish?: (values: any) => void;
  form: FormInstance<any>;
  children?: React.ReactNode;
};
export const SearchForm: React.FC<SearchFormProp> = ({
  onFinish,
  form,
  children,
}) => {
  return (
    <Wrapper>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ minWidth: "100%" }}
      >{children}</Form>
    </Wrapper>
  );
};
