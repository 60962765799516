import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  SaveOutlined,
} from "@ant-design/icons";
import { Button, Flex, Form, Input, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { rest } from "lodash";
import { useEffect } from "react";

type EditPasswordForm = {
  onFinish?: (values: any) => void;
  data: any;
};
export const EditPasswordForm: React.FC<EditPasswordForm> = ({
  onFinish,
  data,
}) => {
  const [form] = useForm();
  useEffect(() => {
    const { _id, ...rest } = data;

    form.setFieldsValue({ _id });
  }, [data]);

  return (
    <Form
      form={form}
      layout="vertical"
      // style={{ maxWidth: 600 }}
      autoComplete="off"
      onFinish={onFinish}
    >
      <Form.Item label="Id" name={"_id"} hidden>
        <Input placeholder="Identificador" />
      </Form.Item>
      <Form.Item label="Senha" name={"password"} rules={[{ required: true }]}>
        <Input.Password
          placeholder="Senha"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      <Form.Item>
        <Flex justify="end">
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
            Salvar
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};
