import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 2px solid #e0e0e0;
  }
  header img {
    max-height: 60px;
  }
  .header-title {
    text-align: left;
    font-size: 0.9rem;
    font-weight: 500;
    color: #1e1e48;

    h1 {
      font-size: 1.3vw;
    }
  }

  .header-filter {
    font-size: 1.5rem;
    font-weight: 500;
    color: #1e1e48;
    display: flex;
    gap: 2px;
    justify-content: flex-end;

    .ant-picker {
      /* width: 50%; */
      padding: 12px;
      font-size: 16px;
    }

    .ant-btn {
      height: 47px;
      width: 47px;
      font-weight: bold;
      background-color: #1e1e48;
      padding: 4px 11px 4px !important;
    }
    .ant-btn:hover {
      background-color: #3d3d9c !important;
    }

    .ant-form-item{
      margin-bottom: 0 !important;
    }
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 10px;
    margin-top: 20px;
  }
  .card {
    background-color: #1e1e48;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .card h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  .card p {
    font-size: 1.2rem;
    font-weight: 300;
  }
  .chart-container {
    margin-top: 40px;
  }
  #financeChart {
    width: 100%;
    height: 400px;
  }
  footer {
    margin-top: 40px;
    text-align: center;
    font-size: 0.9rem;
    color: #777;
  }
`;
