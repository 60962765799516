import { api } from "../../api/api";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import Drawer from "../../components/drawer";
import { Container, GroupButton } from "./style";
import { Button, Col, Divider, Row, Table, Tooltip } from "antd";
import { SearchForm } from "../../forms/positions/SearchForm";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { CreateOrEditForm } from "../../forms/positions/CreateOrEditForm";
import { useSecurity } from "../../hooks/useSecurity";
import { ActionGrid } from "../../components/action-grid";

export const Positions: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [position, setPosition] = useState({});
  const [positions, setPositions] = useState<any[]>([]);
  const [roles, setRoles] = useState<any>();

  const { getPermissions } = useSecurity();

  const getData = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/positions`)))
      .then(({ data }: any) => setPositions(data))
      .finally(() => setLoading(false));
  };

  const handleAdd = () => {
    setAction("add");
    setPosition({});
    setOpen(true);
  };

  const handleEdit = ({ data }: any) => {
    setAction("edit");
    setPosition(data);
    setOpen(true);
  };

  const handleDelete = ({ content }: any) => {
    new Promise((resolve, _) =>
      resolve(api.delete(`/positions/${content?._id}`))
    ).then(() => new Promise((resolve, _) => resolve(getData())));
  };

  const onSubmit = (content: any) => {
    new Promise((resolve, _) => {
      if (content?._id) {
        resolve(api.put(`/positions/${content?._id}`, content));
      } else {
        resolve(api.post(`/positions`, content));
      }
    }).then(() => {
      new Promise((resolve, _) => resolve(getData()));
      setPosition({});
      setOpen(false);
    });
  };

  const onSearch = (content: any) => {
    setLoading(true);

    let body = {};
    for (const key in content || {}) {
      if (content[key] !== undefined) {
        body = {
          ...body,
          [key]: content[key],
        };
      }
    }
    const queryString = new URLSearchParams(body).toString();

    if (String(queryString).length !== 0) {
      new Promise((resolve, _) =>
        resolve(api.get(`/positions?${queryString}`))
      )
        .then(({ data }: any) => setPosition(data))
        .finally(() => setLoading(false));
    } else {
      new Promise((resolve, _) => resolve(api.get(`/positions`)))
        .then(({ data }: any) => setPosition(data))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    const role = getPermissions({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissions]);

  useEffect(() => {
    new Promise((resolve, _) => resolve(getData()));
  }, []);

  const columns: ColumnsType<[]> = [
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    { title: "Situação", dataIndex: "status", key: "status" },
    {
      render: (value, record) => (
        <ActionGrid
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          roles={roles}
          value={value}
        />
      ),
    },
  ];

  return (
    <Container>
      <Row>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 25,
            }}
          >
            <h1>Funções</h1>
            <Button type="primary" onClick={handleAdd} icon={<PlusOutlined />}>
              Novo
            </Button>
          </div>
          <Divider dashed />

          <SearchForm onFinish={onSearch} />
        </Col>

        <Col span={24}>
          <Divider dashed />
        </Col>
        <Col span={24}>
          <Table
            rowKey={"_id"}
            bordered
            loading={loading}
            dataSource={positions}
            columns={columns}
            size="small"
            style={{ width: "100%", overflow: "scroll" }}
          />
        </Col>
      </Row>

      <Drawer
        open={open}
        title={action === "add" ? "Adicionar" : "Editar"}
        destroyOnClose={true}
        onClose={() => setOpen(false)}
        children={
          <CreateOrEditForm onFinish={onSubmit} data={position} />
        }
      />
    </Container>
  );
};
