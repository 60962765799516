import { useEffect, useState } from "react";

import { Button, Col, DatePicker, Form, message, Row, Spin } from "antd";
import { api } from "../../../api/api";
import dayjs from "dayjs";
import { CiSearch } from "react-icons/ci";
import { useForm } from "antd/es/form/Form";
import { Container } from "./style";
import BarChart from "../../../components/charts/BarChart";

export const FinancialCategoriesAnalytics: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{
    receita?: number;
    despesas?: number;
    aPagar?: number;
    aReceber?: number;
    totalGeral?: number;
  }>({});
  const [resumoMensal, setResumoMensal] = useState<any>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [form] = useForm();

  const getData = async (filter?: any) =>
    api.get(`/analytics/financialCategories`, { params: filter });

  const onHandleSearch = () => {
    setLoading(true);
    getData({
      startDate: form.getFieldValue(`date`)?.[0]
        ? dayjs(form.getFieldValue(`date`)?.[0]).format("YYYY-MM-DD")
        : dayjs().startOf("month").format("YYYY-MM-DD"),
      endDate: form.getFieldValue(`date`)?.[1]
        ? dayjs(form.getFieldValue(`date`)?.[1]).format("YYYY-MM-DD")
        : dayjs().endOf("month").format("YYYY-MM-DD"),
    })
      .then(({ data }: any) => {
        const { list } = data;
        setCategories(list.flatMap((e: any) => e?.category));
        setResumoMensal({
          data: list.flatMap((e: any) => e.value),
          colorByPoint: true,
        });
        setLoading(false);
      })
      .catch(() => message.error("Erro ao carregar os dados. Tente novamente"));
  };

  useEffect(() => {
    setLoading(true);

    new Promise((resolve, _) =>
      resolve(
        getData({
          startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
        })
          .then(({ data }: any) => {
            const { list } = data;
            setCategories(list.flatMap((e: any) => e?.category));
            setResumoMensal({
              data: list.flatMap((e: any) => e.value),
              colorByPoint: true,
            });
          })
          .catch(() =>
            message.error("Erro ao carregar os dados. Tente novamente")
          )
          .finally(() => setLoading(false))
      )
    );
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Container>
          <Spin tip={"Carregando..."} spinning={loading}>
            <header>
              <Col span={14}>
                <div className="header-title">
                  <h1>
                    Demonstrativo de Posições Financeiras Por Classificação
                    Financeira
                  </h1>
                </div>
              </Col>
              <Col span={10}>
                <Form form={form} onFinish={onHandleSearch}>
                  <div className="header-filter">
                    <Form.Item name="date">
                      <DatePicker.RangePicker name="date" format="DD/MM/YYYY" />
                    </Form.Item>
                    <Button
                      htmlType="submit"
                      icon={<CiSearch color="#fff" />}
                    />
                  </div>
                </Form>
              </Col>
            </header>

            <Col span={24}>
              

              <div className="chart-container">
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: 500,
                    color: "#1e1e48",
                  }}
                >
                  Visão Agrupado Por Classificação Financeira
                </h3>
                <BarChart categories={categories} data={resumoMensal} />
              </div>
            </Col>
            <Col span={24}>
              <i style={{ fontSize: "10px" }}>
                * No Demonstrativo de Posições Financeiras Por Classificação
                Financeira, são apresentados despesas, ou seja, as saídas
                efetivas de dinheiro que já aconteceram no período analisado
                agrupado pelas classificaçãos financeiras e/ou centro de custo.
                O Demonstrativo de Posições Financeiras Por Classificação
                Financeira reflete o movimento real de recursos financeiros,
                então ele não considera contas a pagar que ainda não foram
                liquidadas.
              </i>
            </Col>
          </Spin>
        </Container>
      </Col>
    </Row>
  );
};
