import { Button, Col, DatePicker, Form, Input, Row, Select, Tag } from "antd";
import { FormContentButtons } from "../../../pages/companies/style";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";

interface SearchFormFieldsProps {
  data?: any;
  onReset?: () => void;
}
export const SearchFormFields = ({ data, onReset }: SearchFormFieldsProps) => {
  const { associates } = data || {};

  return (
    <Row gutter={[8, 2]}>
      <Col xs={24} sm={12} md={12} lg={5}>
        <Form.Item label="Data Início" name={"startDate"}>
          <DatePicker
            placeholder="DD/MM/YYYY"
            style={{ width: "100%" }}
            format={"DD/MM/YYYY"}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={12} lg={5}>
        <Form.Item label="Data Fim" name={"endDate"}>
          <DatePicker
            placeholder="DD/MM/YYYY"
            style={{ width: "100%" }}
            format={"DD/MM/YYYY"}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={14}>
        <Form.Item label="Número de Controle" name={"controlNumber"}>
          <Input placeholder="Número de Controle" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Form.Item label="Associado" name={"associate"}>
          <Select
            placeholder="Associado"
            showSearch
            filterOption={(input, option) =>
              String(option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            options={(associates || []).map((e: any) => ({
              value: e?._id,
              label: e?.name,
            }))}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <FormContentButtons>
          <Form.Item>
            <Button type="default" icon={<ClearOutlined />} onClick={onReset}>
              Limpar
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              Pesquisar
            </Button>
          </Form.Item>
        </FormContentButtons>
      </Col>
    </Row>
  );
};

export const columnsTable: ColumnsType<[]> = [
  {
    title: "Periodo",
    dataIndex: "date",
    render: (value) => dayjs(value).format("MM/YYYY"),
  },
  {
    title: "Número de Controle",
    dataIndex: "controlNumber",
  },
  {
    title: "Associado",
    dataIndex: "associate",
    render: (value) => {
      return value?.[0]?.name || "";
    },
  },
  {
    title: "Valor Desconto",
    dataIndex: "associateMonthlybillingdetails",
    render: (value: any) => (
      <Tag color="blue">
        {new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(
          (value || []).reduce(
            (curr: number, prev: any) => curr + prev.value,
            0
          ) || 0
        )}
      </Tag>
    ),
  },
];
