import { SaveOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Form, Input,  Row } from "antd";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
 

interface WorkshopFormProps {
  onFinish?: (values: any) => void;
  data?:any;
}
export const WorkshopForm: React.FC<WorkshopFormProps> = ({ onFinish,data }) => {
  const [currentValue, setCurrentValue] = useState<number>(0);
  const [form] = Form.useForm();

  const handleCalculateValue = () => {
    const value = +String(form.getFieldValue("value"))
      .replace("R$", "")
      .replace(".", "")
      .replace(",", ".");
    const percent = form.getFieldValue("percent");
    const v = (value || 0) * ((percent || 0) / 100);
    form.setFieldsValue({ valueCalculated: v });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      ...(data?._id ? {_id: data?._id} : {_id: 1 + (new Date().getTime() % (9999999 - 1)) * Math.random()}),
    });
  }, [data]);
  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={[8, 2]}>
        <Col span={24}>
          <Form.Item label="Id" name={"_id"} hidden>
            <Input placeholder="Identificador" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Oficina"
            name={"workshop"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <Input placeholder="Oficína" />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            label="Valor"
            name={"value"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <NumericFormat
              displayType="input"
              thousandSeparator={"."}
              decimalScale={2}
              prefix="R$"
              decimalSeparator=","
              customInput={Input}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            label="%"
            name={"percent"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <NumericFormat
              displayType="input"
              thousandSeparator={"."}
              // decimalScale={2}

              decimalSeparator=","
              customInput={Input}
              onChange={(e: any) => handleCalculateValue()}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Valor Calculado"
            name={"valueCalculated"}
          >
            <NumericFormat
              disabled={true}
              displayType="input"
              thousandSeparator={"."}
              decimalScale={2}
              prefix="R$"
              decimalSeparator=","
              customInput={Input}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Flex justify="end" gap={2}>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Salvar
              </Button>
            </Flex>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
