import { SaveOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Form, Input, Row, Select, Transfer } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { STATUS } from "../../constants/status";

type CreateOrEditForm = {
  onFinish?: (values: any) => void;
  data: any;
};
export const CreateOrEditForm: React.FC<CreateOrEditForm> = ({
  onFinish,
  data,
}) => {
  const [form] = useForm();
  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={[8, 2]}>
        <Col span={24}>
          <Form.Item label="Id" name={"_id"} hidden>
            <Input placeholder="Identificador" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Descrição"
            name={"description"}
            rules={[{ required: true, message: "Preenchimento obrigatório" }]}
          >
            <Input placeholder="Descrição" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Situação" name={"status"}>
            <Select
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={STATUS}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Flex justify="end">
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Salvar
              </Button>
            </Flex>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
