import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { Badge, Table, Typography, Flex } from "antd";
import { ActionGrid } from "../../../components/action-grid";

export const ColumnsTable: any = ({
  roles,
  handleDelete,
  handleEdit,
}: {
  roles: any;
  handleDelete: ({ content }: any) => void;
  handleEdit: ({ content }: any) => void;
}) => {
  return [
    // {
    //   title: "Título",
    //   dataIndex: "code",
    //   key: "code",
    //   width: 200,
    // },

    {
      title: "NFe | NFSe",
      dataIndex: "documentNumber",
      onCell: () => ({ style: { flex: 1 } }),
      key: "documentNumber",
    },
    {
      title: "Competência",
      dataIndex: "competence",
      onCell: () => ({ style: { flex: 1 } }),
      key: "competence",
      render: (_: any, record: any) =>
        record?.competenceMonth &&
        record?.competenceYear && (
          <Typography.Text>
            {dayjs(
              String(`${record?.competenceMonth}/${record?.competenceYear}`),
              "M/YYYY"
            ).format("MM/YYYY")}
          </Typography.Text>
        ),
    },
    {
      title: "Emissão",
      dataIndex: "issueDate",
      onCell: () => ({ style: { flex: 1 } }),
      key: "issueDate",
      render: (value: any) => (value ? dayjs(value).format("DD/MM/YYYY") : ""),
    },
    {
      title: "Vencimento",
      dataIndex: "dueDate",
      onCell: () => ({ style: { flex: 1 } }),
      key: "dueDate",
      render: (value: any) => (value ? dayjs(value).format("DD/MM/YYYY") : ""),
    },
    {
      title: "Pagamento",
      dataIndex: "paymentDate",
      onCell: () => ({ style: { flex: 1 } }),
      key: "paymentDate",
      render: (value: any) => (value ? dayjs(value).format("DD/MM/YYYY") : ""),
    },
    {
      title: "Classificação Financeira",
      dataIndex: "financialCategory",
      onCell: () => ({ style: { flex: 1 } }),
      key: "financialCategory",
      render: (value: any) => (
        <Typography.Text>{value?.description}</Typography.Text>
      ),
    },
    {
      title: "Associado",
      dataIndex: "associate",
      onCell: () => ({ style: { flex: 1 } }),
      key: "associate",
      render: (value: any) => value?.name,
    },
    {
      title: "Forma de Pagamento",
      dataIndex: "paymentType",
      onCell: () => ({ style: { flex: 1 } }),
      key: "paymentType",
      render: (value: any) => (
        <Typography.Text>{value?.description}</Typography.Text>
      ),
    },
    {
      title: "Valor",
      dataIndex: "amount",
      onCell: () => ({ style: { flex: 1 } }),
      key: "amount",
      render: (value: any) => (
        <b>
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(value)}
        </b>
      ),
      align: "end",
    },
    {
      title: "Status",
      key: "status",
      onCell: () => ({ style: { flex: 1 } }),
      dataIndex: "status",
      render: (value: any) => {
        if (value === "unpaid") {
          return <Badge status="default" text="A Vencer" />;
        }
        if (value === "paid") {
          return <Badge status="success" text="Pago" />;
        }
        if (value === "delayed") {
          return <Badge status="error" text="Atrasado" />;
        }
      },
    },
    {
      title: "Data Criação",
      dataIndex: "createdAt",
      key: "createdAt",
      onCell: () => ({ style: { flex: 1 } }),
      render: (value:any) => value && dayjs(value).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Data Última Alteração",
      dataIndex: "updatedAt",
      key: "updatedAt",
      onCell: () => ({ style: { flex: 1 } }),
      render: (value:any) => value && dayjs(value).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Usuário",
      dataIndex: "user",
      key: "user",
      onCell: () => ({ style: { flex: 1 } }),
      render: (value:any) => value?.name,
    },
    {
      onCell: () => ({ style: { flex: 1 } }),
      title: "Manutenção",
      render: (value: any, record: any) => (
        <Flex gap={2}>
          <ActionGrid
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            value={value}
            roles={roles}
          />
        </Flex>
      ),
    },
  ];
};

export const TableSummary = ({ dataSource }: { dataSource: any[] }) => (
  <Table.Summary fixed={"bottom"}>
    <Table.Summary.Row>
      <Table.Summary.Cell index={new Date().getTime()} />
      <Table.Summary.Cell index={new Date().getTime()} />
      <Table.Summary.Cell index={new Date().getTime()} />
      <Table.Summary.Cell index={new Date().getTime()} />
      <Table.Summary.Cell index={new Date().getTime()} />
      <Table.Summary.Cell index={new Date().getTime()} />
      <Table.Summary.Cell index={new Date().getTime()} />
      <Table.Summary.Cell index={1} align="end">
        <b>Total:</b>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={2} align="end">
        {
          <b>
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(
              (dataSource || []).reduce(
                (prev, curr?: any) => prev + Number(curr?.amount || 0),
                0
              )
            )}
          </b>
        }
      </Table.Summary.Cell>
      <Table.Summary.Cell index={new Date().getTime()} />
      <Table.Summary.Cell index={new Date().getTime()} />
    </Table.Summary.Row>
  </Table.Summary>
);
