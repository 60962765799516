import {
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useCallback, useEffect, useState } from "react";
import { Container, LabelWithButton } from "./style";
import {
  IAccident,
  IInsuredVehicle,
  IInsuredWorkshop,
  IThirdPartyVehicle,
  IThirdPartyWorkshop,
} from "../../interfaces/IAccident";
import { TableAccidents } from "./TableAccidents";
import { VehicleForm } from "./VehicleForm";
import { WorkshopForm } from "./WorkshopForm";
import { TableBudget } from "./TableBudget";
import { ECheckListStatus } from "../../enum/ECheckListStatus";

type CreateOrEditForm = {
  onFinish?: (values: any) => void;
  onDownload?: (values: any) => void;
  data: any;
};
export const CreateOrEditForm: React.FC<CreateOrEditForm> = ({
  onFinish,
  data,
  onDownload,
}) => {
  const [form] = useForm<IAccident>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [thirdPartyVehicles, setThirdPartyVehicles] = useState<
    IThirdPartyVehicle[]
  >([]);
  const [insuredVehicles, setInsuredVehicles] = useState<IInsuredVehicle[]>([]);
  const [thirdPartyWorkshops, setThirdPartyWorkshops] = useState<
    IThirdPartyWorkshop[]
  >([]);
  const [insuredWorkshops, setInsuredWorkshops] = useState<IInsuredWorkshop[]>(
    []
  );
  const [titleModal, setTitleModal] = useState("");
  const [currentForm, setCurrentForm] = useState<any>();

  const [insuredVehicle, setInsuredVehicle] = useState<IInsuredVehicle>();
  const [thirdPartyVehicle, setThirdPartyVehicle] =
    useState<IThirdPartyVehicle>();
  const [thirdPartyWorkshop, setThirdPartyWorkshop] =
    useState<IThirdPartyWorkshop>();
  const [insuredWorkshop, setInsuredWorkshop] = useState<IInsuredWorkshop>();

  const handleOpenModal = useCallback(
    ({ cForm, title }: { cForm: any; title: string }) => {
      setIsModalOpen(true);
      setTitleModal(title);
      setCurrentForm(cForm);
    },
    []
  );

  const handleCancel = () => setIsModalOpen(!isModalOpen);

  /*----------edit-----------------------*/
  const handleAddThirdPartyVehicle = useCallback(
    (values: IThirdPartyVehicle) => {
      setThirdPartyVehicles((prev) => [...prev, values]);
      setIsModalOpen(false);
    },
    []
  );
  const handleAddInsuredVehicle = useCallback((value: IInsuredVehicle) => {
    setInsuredVehicles((prev) => [...prev, value]);
    setIsModalOpen(false);
  }, []);
  const handleAddThirdPartyWorkshop = useCallback(
    (value: IThirdPartyWorkshop) => {
      setThirdPartyWorkshops((prev) => [...prev, value]);
      setIsModalOpen(false);
    },
    []
  );
  const handleAddInsuredWorkshop = useCallback((value: IInsuredWorkshop) => {
    setInsuredWorkshops((prev) => [...prev, value]);
    setIsModalOpen(false);
  }, []);

  /*----------edit-----------------------*/
  const onEditThirdPartyVehicle = (values: any) => {
    setThirdPartyVehicle(values.data);
    handleOpenModal({
      cForm: (
        <VehicleForm
          onFinish={handleEditThirdPartyVehicle}
          data={values.data}
        />
      ),
      title: "Editar Terceiro",
    });
  };
  const onEditInsuredVehicle = (values: any) => {
    setInsuredVehicle(values.data);
    handleOpenModal({
      cForm: (
        <VehicleForm onFinish={handleEditInsuredVehicle} data={values.data} />
      ),
      title: "Editar Terceiro",
    });
  };
  const onEditThirdPartyWorkshop = (values: any) => {
    setThirdPartyWorkshop(values.data);
    handleOpenModal({
      cForm: (
        <WorkshopForm
          onFinish={handleEditThirdPartyWorkshop}
          data={values.data}
        />
      ),
      title: "Editar Terceiro",
    });
  };
  const onEditInsuredWorkshop = (values: any) => {
    setInsuredWorkshop(values.data);
    handleOpenModal({
      cForm: (
        <WorkshopForm onFinish={handleEditInsuredWorkshop} data={values.data} />
      ),
      title: "Editar Terceiro",
    });
  };

  /*---------- handle edit -----------------------*/
  const handleEditThirdPartyVehicle = (values: any) => {
    const updated = [
      ...thirdPartyVehicles.filter((item: any) => item._id !== values?._id),
      values,
    ];
    setThirdPartyVehicles(updated);
    setIsModalOpen(false);
  };
  const handleEditInsuredVehicle = (values: any) => {
    const updatedVehicles = [
      ...insuredVehicles.filter((item: any) => item._id !== values?._id),
      values,
    ];
    setInsuredVehicles(updatedVehicles);
    setIsModalOpen(false);
  };
  const handleEditThirdPartyWorkshop = (values: any) => {
    
    const updated = [
      ...thirdPartyWorkshops.filter((item: any) => item._id !== values?._id),
      {
        ...values,
        percent: Number(values?.percent),
      },
    ];
    setThirdPartyWorkshops(updated);
    setIsModalOpen(false);
  };
  const handleEditInsuredWorkshop = (values: any) => {
   
    
    const updated = [
      ...insuredWorkshops.filter((item: any) => item._id !== values?._id),
      values,
    ];
    setInsuredWorkshops(updated);
    setIsModalOpen(false);
  };

  /*------------handle delete---------------------*/
  const handleDeleteThirdPartyVehicle = (values: any) =>
    setThirdPartyVehicles(
      thirdPartyVehicles.filter((item: any) => item._id !== values?.content._id)
    );
  const handleDeleteInsuredVehicle = (values: any) =>
    setInsuredVehicles(
      insuredVehicles.filter((item: any) => item._id !== values?.content._id)
    );
  const handleDeleteThirdPartyWorkshop = (values: any) =>
    setThirdPartyWorkshops(
      thirdPartyWorkshops.filter(
        (item: any) => item._id !== values?.content._id
      )
    );
  const handleDeleteInsuredWorkshop = (values: any) =>
    setInsuredWorkshops(
      insuredWorkshops.filter((item: any) => item._id !== values?.content._id)
    );

  const onSubmit = (values: any) => {
    onFinish!({
      ...values,
      thirdPartyVehicles,
      insuredVehicles,
      thirdPartyWorkshops,
      insuredWorkshops,
    });
  };

  useEffect(() => {
    setThirdPartyVehicles(data?.thirdPartyVehicles ?? []);
    setInsuredVehicles(data?.insuredVehicles ?? []);
    setThirdPartyWorkshops(data?.thirdPartyWorkshops ?? []);
    setInsuredWorkshops(data?.insuredWorkshops ?? []);

    form.setFieldsValue({
      ...data,
      year: new Date().getFullYear(),
    });
  }, [data]);

  return (
    <Container>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Row gutter={[8, 2]}>
          <Col span={24}>
            <Flex justify="end" gap={2}>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Salvar
              </Button>
            </Flex>
          </Col>
          <Col span={24}>
            <Divider />
          </Col>
          <Col span={24}>
            <Tabs
              defaultActiveKey="1"
              style={{
                border: "1px solid var(--codar-bg-100)",
                borderRadius: "10px",
              }}
              type="card"
              tabPosition="left"
              items={[
                {
                  label: `Identificação`,
                  key: "1",
                  children: (
                    <div style={{ marginTop: 25, marginRight: 25 }}>
                      <Row gutter={[8, 2]}>
                        <Col span={24}>
                          <Typography.Title level={5}>
                            Identificação
                          </Typography.Title>
                        </Col>
                        <Col span={24}>
                          <Form.Item label="Id" name={"_id"} hidden>
                            <Input placeholder="Identificador" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Código"
                            name={"code"}
                            rules={[
                              {
                                required: true,
                                message: "Preenchimento obrigatória",
                              },
                            ]}
                          >
                            <Input placeholder="Código" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Ano"
                            name={"year"}
                            // rules={[{ required: true, message: "Preenchimento obrigatória" }]}
                          >
                            <Input placeholder="Ano" disabled={true} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ),
                },
                {
                  label: `Veículos de Terceiros`,
                  key: "2",
                  children: (
                    <div style={{ marginTop: 25, marginRight: 25 }}>
                      <Row gutter={[8, 2]}>
                        <Col span={24}>
                          <Typography.Title level={5}>
                            Veículos de Terceiros
                          </Typography.Title>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label={
                              <LabelWithButton>
                                <span>Terceiros</span>
                                <Tooltip title="Adicionar Terceiros">
                                  <Button
                                    size="small"
                                    type="primary"
                                    htmlType="button"
                                    onClick={() =>
                                      handleOpenModal({
                                        cForm: (
                                          <VehicleForm
                                            onFinish={
                                              handleAddThirdPartyVehicle
                                            }
                                          />
                                        ),
                                        title: "Adicionar Terceiro",
                                      })
                                    }
                                    icon={<PlusOutlined />}
                                  />
                                </Tooltip>
                              </LabelWithButton>
                            }
                            name={"thirdPartyVehicle"}
                          >
                            <TableAccidents
                              handleDelete={handleDeleteThirdPartyVehicle}
                              handleEdit={onEditThirdPartyVehicle}
                              dataSource={thirdPartyVehicles as any}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ),
                },
                {
                  label: `Veículos Segurados`,
                  key: "3",
                  children: (
                    <div style={{ marginTop: 25, marginRight: 25 }}>
                      <Row gutter={[8, 2]}>
                        <Col span={24}>
                          <Typography.Title level={5}>
                            Veículos Segurados
                          </Typography.Title>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label={
                              <LabelWithButton>
                                <span>Segurado</span>
                                <Tooltip title="Adicionar Segurado">
                                  <Button
                                    size="small"
                                    type="primary"
                                    htmlType="button"
                                    onClick={() =>
                                      handleOpenModal({
                                        cForm: (
                                          <VehicleForm
                                            onFinish={handleAddInsuredVehicle}
                                          />
                                        ),
                                        title: "Adicionar Segurado",
                                      })
                                    }
                                    icon={<PlusOutlined />}
                                  />
                                </Tooltip>
                              </LabelWithButton>
                            }
                            name={"insuredVehicle"}
                          >
                            <TableAccidents
                              handleDelete={handleDeleteInsuredVehicle}
                              handleEdit={onEditInsuredVehicle}
                              dataSource={insuredVehicles as any}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ),
                },
                {
                  label: `Orçamentos de Terceiros`,
                  key: "4",
                  children: (
                    <div style={{ marginTop: 25, marginRight: 25 }}>
                      <Row gutter={[8, 2]}>
                        <Col span={24}>
                          <Typography.Title level={5}>
                            Orçamentos de Terceiros
                          </Typography.Title>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label={
                              <LabelWithButton>
                                <span>Orçamentos Terceiros</span>
                                <Tooltip title="Adicionar Orçamentos Terceiros">
                                  <Button
                                    size="small"
                                    type="primary"
                                    htmlType="button"
                                    onClick={() =>
                                      handleOpenModal({
                                        cForm: (
                                          <WorkshopForm
                                            onFinish={
                                              handleAddThirdPartyWorkshop
                                            }
                                          />
                                        ),
                                        title: "Orçamento Aprovado Terceiro",
                                      })
                                    }
                                    icon={<PlusOutlined />}
                                  />
                                </Tooltip>
                              </LabelWithButton>
                            }
                            name={"thirdPartyWorkshop"}
                          >
                            <TableBudget
                              handleDelete={handleDeleteThirdPartyWorkshop}
                              handleEdit={onEditThirdPartyWorkshop}
                              dataSource={thirdPartyWorkshops as any}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ),
                },
                {
                  label: `Orçamentos de Segurados`,
                  key: "5",
                  children: (
                    <div style={{ marginTop: 25, marginRight: 25 }}>
                      <Row gutter={[8, 2]}>
                        <Col span={24}>
                          <Typography.Title level={5}>
                            Orçamentos de Segurados
                          </Typography.Title>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label={
                              <LabelWithButton>
                                <span>Orçamentos Segurado</span>
                                <Tooltip title="Adicionar Orçamentos Segurado">
                                  <Button
                                    size="small"
                                    type="primary"
                                    htmlType="button"
                                    onClick={() =>
                                      handleOpenModal({
                                        cForm: (
                                          <WorkshopForm
                                            onFinish={handleAddInsuredWorkshop}
                                          />
                                        ),
                                        title: "Orçamento Aprovado Segurado",
                                      })
                                    }
                                    icon={<PlusOutlined />}
                                  />
                                </Tooltip>
                              </LabelWithButton>
                            }
                            name={"insuredWorkshop"}
                          >
                            <TableBudget
                              handleDelete={handleDeleteInsuredWorkshop}
                              handleEdit={onEditInsuredWorkshop}
                              dataSource={insuredWorkshops as any}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ),
                },
                {
                  label: `Checklist Obrigatório`,
                  key: "6",
                  children: (
                    <div style={{ marginTop: 25, marginRight: 25 }}>
                      <Row gutter={[8, 2]}>
                        <Col span={24}>
                          <Typography.Title level={5}>
                            Checklist Obrigatório
                          </Typography.Title>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Houve danos no veículo segurado ?"
                            name={"onlyDamagedTheInsuredVehicle"}
                            // rules={[{ required: true, message: "Preenchimento obrigatória" }]}
                          >
                            <Select
                              showSearch={true}
                              filterOption={(input, option) =>
                                String(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              } 
                              placeholder="Selecione"
                              options={Object.entries(ECheckListStatus).map(
                                ([key, value]) => ({
                                  label: key,
                                  value: value,
                                })
                              )}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Houve danos no veículo do terceiro ?"
                            name={"onlyDamagedTheThirdPartyVehicle"}
                            // rules={[{ required: true, message: "Preenchimento obrigatória" }]}
                          >
                            <Select 
                              showSearch
                              filterOption={(input, option) =>
                                String(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              } 
                              placeholder="Selecione"
                              options={Object.entries(ECheckListStatus).map(
                                ([key, value]) => ({
                                  label: key,
                                  value: value,
                                })
                              )}
                            />
                          </Form.Item>
                        </Col>

                        <Divider />

                        <Col span={12}>
                          <Form.Item
                            label="B.0/Análise de acidente encontra-se em anexo ?"
                            name={"policeReportIsAttachment"}
                            // rules={[{ required: true, message: "Preenchimento obrigatória" }]}
                          >
                            <Select 
                              showSearch
                              filterOption={(input, option) =>
                                String(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              placeholder="Selecione"
                              options={Object.entries(ECheckListStatus).map(
                                ([key, value]) => ({
                                  label: key,
                                  value: value,
                                })
                              )}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Vistoria do veículo <b>segurado<b> encontra-se em anexo ?"
                            name={"inspectionInsuredVehicleAttachment"}
                            // rules={[{ required: true, message: "Preenchimento obrigatória" }]}
                          >
                            <Select 
                              showSearch
                              filterOption={(input, option) =>
                                String(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              placeholder="Selecione"
                              options={Object.entries(ECheckListStatus).map(
                                ([key, value]) => ({
                                  label: key,
                                  value: value,
                                })
                              )}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Vistoria do veículo <b>terceiro<b> encontra-se em anexo ?"
                            name={"inspectionThirdPartyVehicleAttachment"}
                            // rules={[{ required: true, message: "Preenchimento obrigatória" }]}
                          >
                            <Select  
                              showSearch
                              filterOption={(input, option) =>
                                String(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              placeholder="Selecione"
                              options={Object.entries(ECheckListStatus).map(
                                ([key, value]) => ({
                                  label: key,
                                  value: value,
                                })
                              )}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Orçamento do veículo <b>segurado<b> encontra-se em anexo ?"
                            name={"budgetInsuredVehicleAttachment"}
                            // rules={[{ required: true, message: "Preenchimento obrigatória" }]}
                          >
                            <Select 
                              showSearch
                              filterOption={(input, option) =>
                                String(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              placeholder="Selecione"
                              options={Object.entries(ECheckListStatus).map(
                                ([key, value]) => ({
                                  label: key,
                                  value: value,
                                })
                              )}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Orçamento do veículo <b>terceiro<b> encontra-se em anexo ?"
                            name={"budgetThirdPartyVehicleAttachment"}
                            // rules={[{ required: true, message: "Preenchimento obrigatória" }]}
                          >
                            <Select 
                              showSearch
                              filterOption={(input, option) =>
                                String(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              placeholder="Selecione"
                              options={Object.entries(ECheckListStatus).map(
                                ([key, value]) => ({
                                  label: key,
                                  value: value,
                                })
                              )}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      </Form>
      <Modal
        destroyOnClose={true}
        title={titleModal}
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {currentForm}
      </Modal>
    </Container>
  );
};
