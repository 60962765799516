import { SaveOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { STATUS } from "../../constants/status";

type CreateOrEditForm = {
  onFinish?: (values: any) => void;
  data: any;
};
export const CreateOrEditForm: React.FC<CreateOrEditForm> = ({
  onFinish,
  data,
}) => {
  const [form] = useForm();
  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={[8, 2]}>
        <Col span={24}>
          <Form.Item label="Id" name={"_id"} hidden>
            <Input placeholder="Identificador" />
          </Form.Item>
        </Col>

        <Col span={18}>
          <Form.Item label="Razão Social" name={"name"}>
            <Input placeholder="Razão Social" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Cnpj" name={"code"}>
            <Input placeholder="Cnpj" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Endereço" name={"address"}>
            <Input placeholder="Endereço" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Telefone" name={"phone"}>
            <Input placeholder="Telefone" />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item label="Email" name={"email"}>
            <Input placeholder="Email" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Site" name={"website"}>
            <Input placeholder="Site" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Situação" name={"status"}>
            <Select
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={STATUS}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Flex justify="end">
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Salvar
              </Button>
            </Flex>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
