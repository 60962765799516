import { SaveOutlined } from "@ant-design/icons";
import { Button, Row, Col, Flex, Form, Input } from "antd";
import { useEffect } from "react";
import { NumericFormat } from "react-number-format";

interface VehicleFormProps {
  onFinish?: (values: any) => void;
  data?: any;
}
export const VehicleForm: React.FC<VehicleFormProps> = ({ onFinish, data }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      ...(data?._id
        ? { _id: data?._id }
        : { _id: 1 + (new Date().getTime() % (9999999 - 1)) * Math.random() }),
    });
  }, [data, form]);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={[8, 2]}>
        <Col span={24}>
          <Form.Item label="Id" name={"_id"} hidden>
            <Input placeholder="Identificador" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Veículo"
            name={"vehicle"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <Input placeholder="Veículo" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Ano"
            name={"year"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <NumericFormat displayType="input" customInput={Input} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Placa"
            name={"licensePlate"}
            rules={[{ required: true, message: "Preenchimento obrigatória" }]}
          >
            <Input placeholder="Placa" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Flex justify="end" gap={2}>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Salvar
              </Button>
            </Flex>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
