import { Button, Drawer, Space } from "antd";
import React, { ReactNode, useState } from "react";

type IDrawerContext = {
  setIsOpen: (open: boolean) => void;
  setClose: (close: any) => void;
  setTitle: (title: any) => void;
  setWidth: (width: any) => void;
  setContent: (content: any) => void;
};

export type IDrawerProvider = {
  children: ReactNode;
};

export const DrawerContext = React.createContext<IDrawerContext>(
  {} as IDrawerContext
);

export const DrawerProvider: React.FC<IDrawerProvider> = ({ children }) => {
  const [isOPen, _setIsOpen] = useState(false);
  const [title, _setTitle] = useState("");
  const [width, _setWidth] = useState(720);
  const [content, _setContent] = useState(null);

  const setIsOpen = (v: any) => _setIsOpen(v);
  const setClose = () => _setIsOpen(false);
  const setTitle = (v: any) => _setTitle(v);
  const setWidth = (v: any) => _setWidth(v);
  const setContent = (v: any) => _setContent(v);

  return (
    <DrawerContext.Provider
      value={{ setIsOpen, setClose, setTitle, setWidth, setContent }}
    >
      <Drawer
        destroyOnClose={true}
        title={title}
        width={width}
        size="large"
        onClose={setClose}
        open={isOPen}
      >
        {content}
      </Drawer>
      {children}
    </DrawerContext.Provider>
  );
};
