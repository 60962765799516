import { Typography } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  .ant-divider {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;

export const LabelWithButton = styled.span`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleHeaderTable = styled.span`
  font-size: 10px;
`;
export const ContentHeaderTable = styled(Typography.Text)`
  font-size: 10px;
`;
