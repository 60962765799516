import { api } from "../../api/api";
import { Container } from "../../components/container";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { Col, Row, Select, Table, Checkbox, Flex, Button, message } from "antd";
import { GrRefresh } from "react-icons/gr";
import { ActionGrid } from "../../components/action-grid";
import { useSecurity } from "../../hooks/useSecurity";

export const Permissions: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [currentUser, setCurrentUser] = useState<string>();
  const [listMenus, setListMenus] = useState<any[]>([]);
  const [roles, setRoles] = useState<any>();

  const { getPermissions:getPermissionsUser } = useSecurity();

  const getUsers = () => {
    new Promise((resolve, _) => resolve(api.get(`/users`))).then(
      ({ data }: any) => setUsers(data)
    );
  };

  const getPermissions = ({ user }: { user: string }) => {
    setLoading(true);
    new Promise((resolve, _) =>
      resolve(api.get(`/permissions?user=${user}`))
    ).then(({ data }: any) => {
      setPermissions(data);
      setListMenus(
        Object.values(data)
          .flat()
          .filter((e:any)=>!e.routeId?.isSubMenu)
          .map((item: any) => ({
            text: item.routeId?.title,
            value: item.routeId?.title,
          }))
      );
    });
    setLoading(false);
  };

  const onHandleChangeUser = ({ value }: { value: string }) => {
    setCurrentUser(value);
    new Promise((resolve, _) => resolve(getPermissions({ user: value })));
  };

  const onHandleChangePermission = ({
    name,
    value,
    record,
  }: {
    name: string;
    value: boolean;
    record: any;
  }) => {
    const { _id, ...rest } = record;
    new Promise((resolve, _) =>
      resolve(api.patch(`/permissions/${_id}`, { [name]: value }))
    );
    new Promise((resolve, _) => resolve(getPermissions({ user: rest.user })));
  };

  const onHandleSyncRoutes = () => {
    if (!currentUser) {
      message.warning("Selecione um usuário");
    } else {
      setLoading(true);
      new Promise((resolve, _) =>
        resolve(api.post(`/permissions/syncUserRoutes`, { user: currentUser }))
      )
        .then(() =>
          new Promise((resolve, _) =>
            resolve(getPermissions({ user: currentUser! }))
          ).then(() => message.success(`Sincronização realizada com sucesso.`))
        )
        .finally(() => setLoading(false));
    }
  };

  const onHandleDeleteRoute = (e:any) => {
    new Promise((resolve, _) =>
      resolve(api.delete(`/permissions/${e?._id}`))
    ).then(() => new Promise((resolve, _) => resolve(getPermissions({ user: currentUser! }))))
    .catch((e:any) => message.error(`Tente novamente`));
  }

  useEffect(() => {
    new Promise((resolve, _) => resolve(getUsers()));
  }, []);

  useEffect(() => {
    const role = getPermissionsUser({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissionsUser]);

  const columns: ColumnsType<[]> = [
    {
      title: "Menu Raiz",
      dataIndex: "routeId",
      key: "root",
      align: "center",
      filters: listMenus,
      onFilter: (value, record: any) => record?.routeId?.root?.includes(value as string),
      render: (value, record) => value?.root
    },
    {
      title: "Título",
      dataIndex: "routeId",
      key: "title",
      align: "center",
      onFilter: (value, record: any) => record?.title.includes(value as string),
      render: (value, record) => value?.title
    },
    {
      title: "É SubMenu",
      dataIndex: "routeId",
      key: "isSubMenu",
      align: "center",
      render: (value) => (value?.isSubMenu && value?.isSubMenu === true ?"Sim" : ""),
    },
    {
      title: "Página",
      dataIndex: "routeId",
      key: "page",
      align: "center",
      render: (value) => value?.page
    },
    {
      title: "Rota",
      dataIndex: "routeId",
      key: "route",
      align: "center",
      render: (value) => value?.route
    },
    {
      title: "Visualizar",
      dataIndex: "show",
      key: "show",
      align: "center",
      render: (value, record) => (
        <Checkbox
          checked={value}
          onChange={(e) =>
            onHandleChangePermission({
              name: "show",
              value: e.target.checked,
              record,
            })
          }
        />
      ),
    },
    {
      title: "Inserir",
      dataIndex: "create",
      key: "create",
      align: "center",
      render: (value, record) => (
        <Checkbox
          checked={value}
          onChange={(e) =>
            onHandleChangePermission({
              name: "create",
              value: e.target.checked,
              record,
            })
          }
        />
      ),
    },
    {
      title: "Editar",
      dataIndex: "edit",
      key: "edit",
      align: "center",
      render: (value, record) => (
        <Checkbox
          checked={value}
          onChange={(e) =>
            onHandleChangePermission({
              name: "edit",
              value: e.target.checked,
              record,
            })
          }
        />
      ),
    },
    {
      title: "Excluir",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      render: (value, record) => (
        <Checkbox
          checked={value}
          onChange={(e) =>
            onHandleChangePermission({
              name: "delete",
              value: e.target.checked,
              record,
            })
          }
        />
      ),
    },
    {
      render: (value, record) => (
        <ActionGrid
          handleEdit={()=>{}}
          handleDelete={()=>onHandleDeleteRoute(value)}
          roles={roles}
          value={value}
        />
      ),
    }
  ];

  return (
    <Container>
      <Row>
        <Col span={24}>
          <Select
            showSearch
            filterOption={(input, option) =>
              String(option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            style={{ width: "100%", marginBottom: 10 }}
            placeholder="Usuário"
            onChange={(e) => onHandleChangeUser({ value: e })}
            options={users.map((e: any) => ({ value: e._id, label: e.name }))}
          />
        </Col>

        <Col span={24}>
          <Flex justify="end">
            <Button
              style={{
                alignContent: "end",
                marginBottom: 10,
              }}
              type="default"
              onClick={onHandleSyncRoutes}
              icon={<GrRefresh />}
            >
              Sincronizar Rotas
            </Button>
          </Flex>
        </Col>

        <Col span={24}>
          <Table
            rowKey={"_id"}
            bordered
            loading={loading}
            dataSource={permissions}
            columns={columns}
            size="small"
            style={{ width: "100%", overflow: "scroll" }}
          />
        </Col>
      </Row>
    </Container>
  );
};
