import React, { useRef } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import HC_exporting from "highcharts/modules/exporting";
// HC_exporting(Highcharts);

type BarChartProp = {
  title?: string;
  data?: any;
  categories?: any;
  drilldown?: any;
};
const BarChart: React.FC<BarChartProp> = (
  { title, data, categories },
  props
) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const options: Highcharts.Options = {
    chart: {
      type: "bar",
    },
    title: {
      text: title,
    },
    xAxis: {
      categories,
    },
    yAxis: {
      labels: {
          formatter: function () {
              return 'R$ ' + Highcharts.numberFormat((this.value as any), 2, ',', '.');
          }
      },
      title: {
          text: 'Valores'
      }
  },
    plotOptions: {
      bar: {
        // pointPadding: 0.1, // Menor padding entre barras para elas ficarem mais largas
        // groupPadding: 0,   // Remove espaçamento entre grupos de barras
        pointWidth: 25,    // Definir uma largura fixa para as barras
        dataLabels: {
          enabled: true,
          formatter: function () {
            return new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format((this as any)?.y);
          },
        },
      },
    },
    tooltip: {
      pointFormatter: function () {
        return (
          '<span style="color:' +
          this.color +
          '">\u25CF</span> ' +
          this.series.name +
          ": <b>" +
          new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format((this as any).y) +
          "</b><br/>"
        );
      },
    },
    
    series: data,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
      {...props}
    />
  );
};

export default BarChart;
