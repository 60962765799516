import { Button, Col, Divider, Row, Table, Tooltip } from "antd";
import { Container, GroupButton } from "./style";
import { useEffect, useState } from "react";
import { api } from "../../api/api";
import { ColumnsType } from "antd/es/table";
import Drawer from "../../components/drawer";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { SearchForm } from "../../forms/vehicles/SearchForm";
import { CreateOrEditForm } from "../../forms/vehicles/CreateOrEditForm";
import { ActionGrid } from "../../components/action-grid";
import { useSecurity } from "../../hooks/useSecurity";

export const Vehicles: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [vehicle, setVehicle] = useState({});
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [vehicleCategories, setVehicleCategories] = useState<any[]>([]);
  const [roles, setRoles] = useState<any>();

  const { getPermissions } = useSecurity();

  const getData = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/vehicles`)))
      .then(({ data }: any) => setVehicles(data))
      .finally(() => setLoading(false));
  };

  const getVehicles = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/vehicleCategories`)))
      .then(({ data }: any) => setVehicleCategories(data))
      .finally(() => setLoading(false));
  };

 

  const handleAdd = () => {
    setAction("add");
    setVehicle({});
    setOpen(true);
  };

  const handleEdit = ({ data }: any) => {
    setAction("edit");
    setVehicle(data);
    setOpen(true);
  };

  const handleDelete = ({ content }: any) => {
    new Promise((resolve, _) =>
      resolve(api.delete(`/vehicles/${content?._id}`))
    ).then(() => new Promise((resolve, _) => resolve(getData())));
  };

  const onSubmit = (content: any) => {
    new Promise((resolve, _) => {
      if (content?._id) {
        resolve(api.put(`/vehicles/${content?._id}`, content));
      } else {
        resolve(api.post(`/vehicles`, content));
      }
    }).then(() => {
      new Promise((resolve, _) => resolve(getData()));
      setVehicle({});
      setOpen(false);
    });
  };

  const onSearch = (content: any) => {
    setLoading(true);

    let body = {};
    for (const key in content || {}) {
      if (content[key] !== undefined) {
        body = {
          ...body,
          [key]: content[key],
        };
      }
    }
    const queryString = new URLSearchParams(body).toString();

    if (String(queryString).length !== 0) {
      new Promise((resolve, _) => resolve(api.get(`/vehicles?${queryString}`)))
        .then(({ data }: any) => setVehicles(data))
        .finally(() => setLoading(false));
    } else {
      new Promise((resolve, _) => resolve(api.get(`/vehicles`)))
        .then(({ data }: any) => setVehicles(data))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    new Promise((resolve, _) => resolve(getData()));
    new Promise((resolve, _) => resolve(getVehicles()));
  }, []);

  useEffect(() => {
    const role = getPermissions({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissions]);

  const columns: ColumnsType<[]> = [
    {
      title: "Código ITA",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Placa",
      dataIndex: "plate",
      key: "plate",
    },
    {
      title: "Categoria",
      dataIndex: "vehicleCategory",
      key: "vehicleCategory",
      render: (value) => value?.description,
    },
    
    { title: "Situação", dataIndex: "status", key: "status" },
    {
          render: (value, record) => (
            <ActionGrid
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              roles={roles}
              value={value}
            />
          ),
    },
  ];

  return (
    <Container>
      <Row>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 25,
            }}
          >
            <h1>Veículos</h1>
            <Button type="primary" onClick={handleAdd} icon={<PlusOutlined />}>
              Novo
            </Button>
          </div>
          <Divider dashed />

          <SearchForm onFinish={onSearch} />
        </Col>

        <Col span={24}>
          <Divider dashed />
        </Col>
        <Col span={24}>
          <Table
            rowKey={"_id"}
            bordered
            loading={loading}
            dataSource={vehicles}
            columns={columns}
            size="small"
            style={{ width: "100%", overflow: "scroll" }}
          />
        </Col>
      </Row>

      <Drawer
        open={open}
        title={action === "add" ? "Adicionar" : "Editar"}
        destroyOnClose={true}
        onClose={() => setOpen(false)}
        children={
          <CreateOrEditForm
            onFinish={onSubmit}
            data={{ vehicle, vehicleCategories,   }}
          />
        }
      />
    </Container>
  );
};
