import { SaveOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Flex, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

type CreateOrEditForm = {
  onFinish?: (values: any) => void;
  data: any;
};
export const CreateOrEditForm: React.FC<CreateOrEditForm> = ({
  onFinish,
  data,
}) => {
  const [showPaymentDateField, setShowPaymentDateField] = useState(false);

  const handleChangeStatus = (value: any) => {
    if (value === "paid") {
      setShowPaymentDateField(true);
    } else {
      setShowPaymentDateField(false);
    }
  };

  const [form] = useForm();
  useEffect(() => {
    form.setFieldsValue({
      ...data?.accountReceivable,
      ...(data?.accountReceivable?.date
        ? { date: dayjs(data?.accountReceivable?.date, "YYYY-MM-DD") }
        : { date: dayjs() }),
      ...(data?.accountReceivable?.dueDate
        ? { dueDate: dayjs(data?.accountReceivable?.dueDate, "YYYY-MM-DD") }
        : {}),
      ...(data?.accountReceivable?.paymentDate
        ? {
            paymentDate: dayjs(data?.accountReceivable?.paymentDate, "YYYY-MM-DD"),
          }
        : {}),
      ...(data?.accountReceivable?.issueDate
        ? { issueDate: dayjs(data?.accountReceivable?.issueDate, "YYYY-MM-DD") }
        : {}),
      ...(data?.accountReceivable?.associate?._id
        ? { associate: data?.accountReceivable?.associate?._id }
        : { associate: data?.accountReceivable?.associate }),
      ...(data?.accountReceivable?.documentType?._id
        ? { documentType: data?.accountReceivable?.documentType?._id }
        : { documentType: data?.accountReceivable?.documentType }),
      ...(data?.accountReceivable?.paymentType?._id
        ? { paymentType: data?.accountReceivable?.paymentType?._id }
        : { paymentType: data?.accountReceivable?.paymentType }),
      ...(data?.accountReceivable?.financialCategory?._id
        ? { financialCategory: data?.accountReceivable?.financialCategory?._id }
        : { financialCategory: data?.accountReceivable?.financialCategory }),
      ...(data?.accountReceivable?.competenceMonth &&
      data?.accountReceivable?.competenceYear
        ? {
            competence: dayjs(
              `${data?.accountReceivable?.competenceMonth}/${data?.accountReceivable?.competenceYear}`,
              "M/YYYY"
            ),
          }
        : {}),
    });
  }, []);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={[8, 2]}>
        <Col span={24}>
          <Form.Item label="Id" name={"_id"} hidden>
            <Input placeholder="Identificador" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Data Entrada" name={"date"} hidden>
            <DatePicker
              style={{ width: "100%" }}
              format={"DD/MM/YYYY"}
              placeholder="Data Entrada"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Associado"
            name={"associate"}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={data?.associates.flatMap((e: any) => [
                { value: e?._id, label: e?.name },
              ])}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Tipo do Documento" name={"documentType"}>
            <Select
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={data?.documentTypes.flatMap((e: any) => [
                { value: e?._id, label: e?.description },
              ])}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Número NFE/NFSE"
            name={"documentNumber"}
            rules={[{ required: true }]}
          >
            <Input placeholder="Número NFE/NFSE" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Valor" name={"amount"} rules={[{ required: true }]}>
            <NumericFormat
              displayType="input"
              thousandSeparator={"."}
              decimalScale={2}
              prefix="R$"
              decimalSeparator=","
              customInput={Input}
            />
          </Form.Item>
        </Col>{" "}
        <Col span={8}>
          <Form.Item
            label="Emissão"
            name={"issueDate"}
            rules={[{ required: true }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              format={"DD/MM/YYYY"}
              placeholder="Emissão"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Competência"
            name={"competence"}
            rules={[{ required: true }]}
          >
            <DatePicker
              picker="month"
              style={{ width: "100%" }}
              format={"MM/YYYY"}
              placeholder="Competência"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Vencimento"
            name={"dueDate"}
            rules={[{ required: true }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              format={"DD/MM/YYYY"}
              placeholder="Vencimento"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Forma de Pagamento"
            name={"paymentType"}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={data?.paymentTypes.flatMap((e: any) => [
                { value: e?._id, label: e?.description },
              ])}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Classificação Financeira"
            name={"financialCategory"}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={data?.financialCategories?.flatMap((e: any) => [
                { value: e?._id, label: e?.description },
              ])}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Situação"
            name={"status"}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              onChange={handleChangeStatus}
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={[
                { value: "unpaid", label: "Não Pago" },
                { value: "paid", label: "Pago" },
              ]}
            />
          </Form.Item>
        </Col>
        {(showPaymentDateField || data?.accountReceivable?.status === "paid") && (
          <Col span={12}>
            <Form.Item
              label="Data Pagamento"
              name={"paymentDate"}
              rules={[{ required: true }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                format={"DD/MM/YYYY"}
                placeholder="Data Pagamento"
              />
            </Form.Item>
          </Col>
        )}
        <Col span={24}>
          <Form.Item>
            <Flex justify="end">
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Salvar
              </Button>
            </Flex>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
