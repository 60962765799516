import { Container } from "../../components/container";
import { Col, Row, Typography } from "antd";
import { useSecurity } from "../../hooks/useSecurity";
import { FinancesAnalytics } from "../analytics/financesAnalytics";
import { CashFlowsAnalytics } from "../analytics/cashFlowAnalitics";
import { FinancialCategoriesAnalytics } from "../analytics/financialCategories";

type HomeProp = {};
export const Home: React.FC<HomeProp> = () => {
  const { userInfo } = useSecurity();

  return (
    <Container>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Typography.Paragraph>
            <Typography.Title level={4}>
              Bem vindo {userInfo?.name || ""}
            </Typography.Title>
          </Typography.Paragraph>
        </Col>

        {userInfo?.permissions.find(
          (e: any) => e?.route === "/analytics" && e?.show
        ) && (
          <>
            <Col span={24}>
              <FinancialCategoriesAnalytics />
            </Col>

            <Col span={12}>
              <FinancesAnalytics />
            </Col>
            <Col span={12}>
              <CashFlowsAnalytics />
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};
