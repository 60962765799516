import { Col, Divider, Row, Table } from "antd";
import { Container } from "../../components/container";
import { useEffect, useState } from "react";
import { api } from "../../api/api";
import { ColumnsType } from "antd/es/table";
import Drawer from "../../components/drawer";
import { SearchForm } from "../../forms/financialCategories/SearchForm";
import { CreateOrEditForm } from "../../forms/financialCategories/CreateOrEditForm";
import { PageHeader } from "../../components/page-header";
import { ActionGrid } from "../../components/action-grid";
import { useSecurity } from "../../hooks/useSecurity";
import { DiscountType } from "../../enum/EDiscountType";

export const FinancialCategory: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [financialCategory, setFinancialCategory] = useState({});
  const [financialCategories, setFinancialCategories] = useState<any[]>([]);
  const [roles, setRoles] = useState<any>();

  const { getPermissions } = useSecurity();

  const getData = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/financialCategories`)))
      .then(({ data }: any) => setFinancialCategories(data))
      .finally(() => setLoading(false));
  };

  const handleAdd = () => {
    setAction("add");
    setFinancialCategory({});
    setOpen(true);
  };

  const handleEdit = ({ data }: any) => {
    setAction("edit");
    setFinancialCategory(data);
    setOpen(true);
  };

  const handleDelete = ({ content }: any) => {
    new Promise((resolve, _) =>
      resolve(api.delete(`/financialCategories/${content?._id}`))
    ).then(() => new Promise((resolve, _) => resolve(getData())));
  };

  const onSubmit = (content: any) => {
    new Promise((resolve, _) => {
      if (content?._id) {
        resolve(
          api.put(`/financialCategories/${content?._id}`, {
            ...content,
            ...(content?.valueDiscount
              ? {
                  valueDiscount: String(content?.valueDiscount)
                    .replace("R$", "")
                    .replace(".", "")
                    .replace(",", "."),
                }
              : {}),
          })
        );
      } else {
        resolve(
          api.post(`/financialCategories`, {
            ...content,
            ...(content?.valueDiscount
              ? {
                  valueDiscount: String(content?.valueDiscount)
                    .replace("R$", "")
                    .replace(".", "")
                    .replace(",", "."),
                }
              : {}),
          })
        );
      }
    }).then(() => {
      new Promise((resolve, _) => resolve(getData()));
      setFinancialCategory({});
      setOpen(false);
    });
  };

  const onSearch = (content: any) => {
    setLoading(true);

    let body = {};
    for (const key in content || {}) {
      if (content[key] !== undefined) {
        body = {
          ...body,
          [key]: content[key],
        };
      }
    }
    const queryString = new URLSearchParams(body).toString();

    if (String(queryString).length !== 0) {
      new Promise((resolve, _) =>
        resolve(api.get(`/financialCategories?${queryString}`))
      )
        .then(({ data }: any) => setFinancialCategories(data))
        .finally(() => setLoading(false));
    } else {
      new Promise((resolve, _) => resolve(api.get(`/financialCategories`)))
        .then(({ data }: any) => setFinancialCategories(data))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    new Promise((resolve, _) => resolve(getData()));
  }, []);

  useEffect(() => {
    const role = getPermissions({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissions]);

  const columns: ColumnsType<[]> = [
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
    },
    { title: "Descrição", dataIndex: "description", key: "description" },
    {
      title: "Aplicar Desconto Fixo",
      dataIndex: "fixedDiscount",
      key: "fixedDiscount",
      render: (value: any) => (value ? (value === true ? "Sim" : "Não") : ""),
    },
    {
      title: "Tipo do Desconto",
      dataIndex: "typeDiscount",
      key: "typeDiscount",
      render: (value: any) => DiscountType[value],
    },
    {
      title: "Valor Desconto",
      dataIndex: "valueDiscount",
      key: "valueDiscount",
      render: (value: any, record: any) => (
        <b>
          {record?.typeDiscount === "FIXED"
            ? new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)
            : `${Number(value || 0).toFixed(2)}%`}
        </b>
      ),
    },
    { title: "Situação", dataIndex: "status", key: "status" },
    {
      render: (value, record) => (
        <ActionGrid
          handleEdit={() => handleEdit({ data: value })}
          handleDelete={() => handleDelete({ content: value })}
          roles={roles}
          value={value}
        />
      ),
    },
  ];

  return (
    <Container>
      <Row>
        <Col span={24}>
          <PageHeader
            title="Classificação Financeira"
            onClickAdd={() => handleAdd()}
            roles={roles}
          />
          <SearchForm onFinish={onSearch} />
        </Col>

        <Col span={24}>
          <Divider dashed />
        </Col>
        <Col span={24}>
          <Table
            rowKey={"_id"}
            bordered
            loading={loading}
            dataSource={financialCategories}
            columns={columns}
            size="small"
            style={{ width: "100%", overflow: "scroll" }}
          />
        </Col>
      </Row>

      <Drawer
        open={open}
        title={action === "add" ? "Adicionar" : "Editar"}
        destroyOnClose={true}
        onClose={() => setOpen(false)}
        children={
          <CreateOrEditForm onFinish={onSubmit} data={financialCategory} />
        }
      />
    </Container>
  );
};
