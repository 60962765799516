import { Button, Col, Divider, message, Row, Table } from "antd";
import { PageHeader } from "../../components/page-header";
import { useEffect, useState } from "react";
import { useSecurity } from "../../hooks/useSecurity";
import { SearchForm } from "../../components/search-form";
import { useForm } from "antd/es/form/Form";
import {
  columnsTable,
  SearchFormFields,
} from "../../constants/reports/employees/employeesWithAssociatesReport";
import { api } from "../../api/api";
import { FileExcelFilled } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { Container } from "../../components/container";

export const EmployeesWithAssociatesReport: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState<any[]>([]);
  const [associates, setAssociates] = useState<any[]>([]);
  const [roles, setRoles] = useState<any>();
  const { getPermissions } = useSecurity();
  const [form] = useForm();

  const onSearch = (values: any) => {
    setLoading(true);
    new Promise((resolve, _) =>
      resolve(
        api.post(`/employees-report/employeesWithAssociatesReport`, {
          ...values,
        })
      )
    )
      .then(({ data }: any) => setEmployees(data))
      .finally(() => {
        setLoading(false);
        form.resetFields();
      });
  };

  const onReset = () => {
    setLoading(true);
    form.resetFields();
    setEmployees([]);
    setLoading(false);
  };
  

  const getData = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/associates`)))
      .then(({ data }: any) => setAssociates(data))
      .finally(() => setLoading(false));
  };

  const downloadExcel = () => {
    try {
      setLoading(true);
      const worksheet = XLSX.utils.json_to_sheet(employees);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Plan1");
      XLSX.writeFile(workbook, "Relação de Funcionários por Associados.xlsx");
       
    } catch (error: any) {
      message.error("Falha ao exportar dados. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    new Promise((resolve, _) => resolve(getData()));
  }, []);

  useEffect(() => {
    const role = getPermissions({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissions]);

  return (
    <Container>
      <Row>
        <Col span={24}>
          <PageHeader
            title="Relação de Funcionários por Associados"
            roles={roles}
          >
            <Button
              type="default"
              icon={<FileExcelFilled />}
              loading={loading}
              onClick={downloadExcel}
              disabled={loading || employees.length === 0}
            >
             Exportar
            </Button>
          </PageHeader>
          <SearchForm
            onFinish={onSearch}
            form={form}
            children={SearchFormFields({
              onReset,
              data: {
                associates,
              },
            })}
          />
        </Col>
        <Col span={24}>
          <Divider dashed />
        </Col>
        <Col span={24}>
          <Table
            rowKey={"_id"}
            bordered
            loading={loading}
            dataSource={employees}
            columns={columnsTable}
            size="small"
            style={{ width: "100%", overflow: "scroll" }}
          />
        </Col>
      </Row>
    </Container>
  );
};
