import { Button, Col, Divider, Row, Table, message } from "antd";
import { Container } from "../../components/container";
import { PageHeader } from "../../components/page-header";
import { FileExcelFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useSecurity } from "../../hooks/useSecurity";
import * as XLSX from "xlsx";
import { useForm } from "antd/es/form/Form";
import {
  columnsTable,
  SearchFormFields,
} from "../../constants/reports/associateMonthlyBilling/reportAssociateMonthlyBilling";
import { SearchForm } from "../../components/search-form";
import { api } from "../../api/api";
import dayjs from "dayjs";

export const ReportAssociateMonthlyBilling: React.FC<any> = () => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<any>();
  const [associateMonthlyBilling, setAssociateMonthlyBilling] = useState<any[]>(
    []
  );
  const [associates, setAssociates] = useState<any[]>([]);
  const [params, setParams] = useState<any>({
    startDate: "",
    endDate: "",
    controlNumber: "",
    associate: "",
  });

  const { getPermissions } = useSecurity();
  const [form] = useForm();

  const getAssociateMonthlyBilling = (filter?: any) => {
    setLoading(true);
    new Promise((resolve, _) =>
      resolve(
        api.get(`/associate-monthly-billing/reportAssociateMonthlyBilling`, {
          params: filter,
        })
      )
    )
      .then(({ data }: any) => setAssociateMonthlyBilling(data))
      .finally(() => {
        setLoading(false);
        form.resetFields();
      });
  };

  const getAssociates = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/associates`)))
      .then(({ data }: any) => setAssociates(data))
      .finally(() => setLoading(false));
  };

  const onSearch = () => {
    setParams((prev: any) => ({
      ...prev,
      ...form.getFieldsValue(),
      ...(form?.getFieldValue("startDate")
        ? {
            startDate: dayjs(form?.getFieldValue("startDate")).format(
              "YYYY-MM-DD"
            ),
          }
        : {}),
      ...(form?.getFieldValue("endDate")
        ? {
            endDate: dayjs(form?.getFieldValue("endDate")).format("YYYY-MM-DD"),
          }
        : {}),
    }));
  };

  const onReset = () => {
    setLoading(true);
    form.resetFields();
    setParams({
      startDate: "",
      endDate: "",
      controlNumber: "",
      associate: "",
    });
    setLoading(false);
  };

  const downloadExcel = () => {
    try {
      setLoading(true);
      const worksheet = XLSX.utils.json_to_sheet(
        associateMonthlyBilling.map((e) => {
          return {
            Periodo: dayjs(e.date).format("MM/YYYY"),
            "Número de Controle": e.controlNumber,
            Associado: e.associate?.[0]?.name,
            "Valor Desconto": e.associateMonthlybillingdetails.reduce(
              (prev: number, curr: any) => prev + curr.value,
              0
            ),
          };
        })
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Plan1");
      XLSX.writeFile(workbook, "Fechamento.xlsx");
    } catch (error: any) {
      message.error("Falha ao exportar dados. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    new Promise((resolve, _) => resolve(getAssociates()));
  }, []);

  useEffect(() => {
    const role = getPermissions({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissions]);

  useEffect(() => {
    getAssociateMonthlyBilling(params);
  }, [params]);

  return (
    <Container>
      <Row>
        <Col span={24}>
          <PageHeader
            title="Relação de Fechamentos de Associados"
            roles={roles}
          >
            <Button
              type="default"
              icon={<FileExcelFilled />}
              loading={loading}
              onClick={downloadExcel}
              disabled={loading || associateMonthlyBilling.length === 0}
            >
              Exportar
            </Button>
          </PageHeader>
          <SearchForm
            onFinish={onSearch}
            form={form}
            children={SearchFormFields({
              onReset,
              data: {
                associateMonthlyBilling,
                associates,
              },
            })}
          />
        </Col>
        <Col span={24}>
          <Divider dashed />
        </Col>
        <Col span={24}>
          <Table
            rowKey={"_id"}
            bordered
            loading={loading}
            dataSource={associateMonthlyBilling}
            columns={columnsTable}
            size="small"
            style={{ width: "100%", overflow: "scroll" }}
          />
        </Col>
      </Row>
    </Container>
  );
};
