import styled from "styled-components";



export const GroupButton = styled.div`
  display: flex;
  gap: 2px;
`;

export const FormContent = styled.div`
  /* display: block; */
  width: 100%;
`;
export const FormContentItems = styled.div`
  display: flex;
  flex-direction: row;
   margin-top:10px;
`;
export const FormContentButtons = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;
