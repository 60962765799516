import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { FormContentButtons } from "./style";

type SearchFormProp = {
  onFinish?: (values: any) => void;
  onClear: () => void;
};
export const SearchForm: React.FC<SearchFormProp> = ({ onFinish, onClear }) => {
  const [form] = useForm();

  return (
    <div
      style={{
        border: "1px solid #c6c66c6",
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ minWidth: "100%" }}
      >
        <Form.Item label="Descrição" name={"root"}>
          <Input placeholder="Descrição" />
        </Form.Item>
        <Form.Item>
          <FormContentButtons>
            <Button
              type="default"
              htmlType="submit"
              icon={<ClearOutlined />}
              onClick={() => {
                onClear();
                form.resetFields();
              }}
            >
              Limpar
            </Button>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              Pesquisar
            </Button>
          </FormContentButtons>
        </Form.Item>
      </Form>
    </div>
  );
};
