import React from "react";
import { Routes } from "./routes";
import { ConfigProvider } from "antd";
import pt_BR from "antd/locale/pt_BR";
import GlobalStyles from "./theme/globalStyle";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { SecurityProvider } from "./context/SecurityContext";
import { DrawerProvider } from "./context/DrawerContext";

const App: React.FC = () => {
  return (
    <ConfigProvider locale={pt_BR}>
      <BrowserRouter>
        <AuthProvider>
          <SecurityProvider>
            <DrawerProvider>
              <Routes />
              <GlobalStyles />
            </DrawerProvider>
          </SecurityProvider>
        </AuthProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
