import dayjs from "dayjs";
import { api } from "../../api/api";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { useDrawer } from "../../hooks/useDrawer";
import { Col, Divider, Row, Table, Tag } from "antd";
import { useSecurity } from "../../hooks/useSecurity";
import { Container } from "../../components/container";
import { PageHeader } from "../../components/page-header";
import { ActionGrid } from "../../components/action-grid";
import { SearchForm } from "../../forms/employeeDocuments/SearchForm";
import { TableColumns } from "../../constants/pages/employeeDocuments";
import { CreateOrEditForm } from "../../forms/employeeDocuments/CreateOrEditForm";

export const EmployeeDocuments: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [roles, setRoles] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadloading, setDownloadloading] = useState(false);
  const [employees, setEmployees] = useState<any>([]);
  const [employeeDocument, setEmployeeDocument] = useState<any>({});
  const [employeeDocuments, setEmployeeDocuments] = useState<any[]>([]);
  const { getPermissions } = useSecurity();
  const { setIsOpen, setTitle, setContent, setClose } = useDrawer();
  const [params, setParams] = useState<any>({
    competence: "",
    financialCategory: "",
    startIssueDate: "",
    endIssueDate: "",
    startDueDate: "",
    endDueDate: "",
    status: "",
  });

  const getData = (filter?: any) => {
    setLoading(true);
    new Promise((resolve, _) =>
      resolve(
        api.get(`/employeeDocument`, {
          params: filter,
        })
      )
    )
      .then(({ data }: any) => {
        setEmployeeDocuments(data);
      })
      .finally(() => setLoading(false));
  };

  const getEmployee = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/employees`)))
      .then(({ data }: any) => {
        setEmployees(data);
      })
      .finally(() => setLoading(false));
  };

  const handleAdd = () => {
    setIsOpen(true);
    setTitle("Adicionar");
    setContent(
      <CreateOrEditForm
        onFinish={onSubmit}
        data={{
          employeeDocuments,
          employees,
        }}
      />
    );
  };

  const handleEdit = ({ data }: { data?: any }) => {
    setIsOpen(true);
    setTitle("Editar");
    setContent(
      <CreateOrEditForm
        onFinish={onSubmit}
        data={{
          employeeDocument: data,
          employees,
        }}
      />
    );
  };

  const handleDelete = ({ content }: any) => {
    new Promise((resolve, _) =>
      resolve(api.delete(`/employeeDocument/${content?._id}`))
    ).then(() => new Promise((resolve, _) => resolve(getData())));
  };

  const onSearch = (data: any) => {
    const serialized = {
      ...(data?.competence
        ? { competence: dayjs(data?.competence).format("MM/YYYY") }
        : {}),
      ...(data?.startIssueDate
        ? { startIssueDate: dayjs(data?.startIssueDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.endIssueDate
        ? { endIssueDate: dayjs(data?.endIssueDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.startDueDate
        ? { startDueDate: dayjs(data?.startDueDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.endDueDate
        ? { endDueDate: dayjs(data?.endDueDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.startPaymentDate
        ? {
            startPaymentDate: dayjs(data?.startPaymentDate).format(
              "YYYY-MM-DD"
            ),
          }
        : {}),
      ...(data?.endPaymentDate
        ? { endPaymentDate: dayjs(data?.endPaymentDate).format("YYYY-MM-DD") }
        : {}),
      ...(data?.status ? { status: data?.status } : {}),
      ...(data?.financialCategory
        ? { financialCategory: data?.financialCategory }
        : {}),
    };
    setParams((prev: any) => ({ ...prev, serialized }));
    getData(serialized);
  };

  const onReset = () => {
    setLoading(true);
    setParams({
      competence: "",
      financialCategory: "",
      startIssueDate: "",
      endIssueDate: "",
      startDueDate: "",
      endDueDate: "",
      status: "",
    });
    setLoading(false);
    getData();
  };

  const onSubmit = (content: any) => {
    new Promise((resolve, _) => {
      if (content?._id) {
        resolve(
          api.patch(`/employeeDocument/${content?._id}`, {
            ...content,
            ...(content?.competence
              ? {
                  competenceMonth: dayjs(content?.competence).month() + 1,
                  competenceYear: dayjs(content?.competence).year(),
                }
              : {}),
          })
        );
      } else {
        resolve(
          api.post(`/employeeDocument`, {
            ...content,
            ...(content?.competence
              ? {
                  competenceMonth: dayjs(content?.competence).month() + 1,
                  competenceYear: dayjs(content?.competence).year(),
                }
              : {}),
          })
        );
      }
    })
      .then(() => {
        new Promise((resolve, _) => resolve(getData()));
        setEmployeeDocument({});
        setOpen(false);
      })
      .finally(() => {
        setLoading(false);
        setClose(true);
      });
  };

  useEffect(() => {
    new Promise((resolve, _) => resolve(getEmployee()));
    new Promise((resolve, _) => resolve(getData()));
  }, []);

  useEffect(() => {
    const role = getPermissions({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissions]);

  const columns: ColumnsType<[]> = [
    {
      title: "Data",
      dataIndex: "date",
      key: "date",

      render: (value) => (value ? dayjs(value).format("DD/MM/YYYY") : ""),
      align: "center",
    },
    {
      title: "Número de Controle",
      dataIndex: "controlNumber",
      key: "controlNumber",

      align: "center",
      onCell: () => ({ style: { flex: 1 } }),
    },
    {
      title: "Associado",
      dataIndex: "associate",
      key: "associate",

      render: (value, record) => value?.name,
      onCell: () => ({ style: { flex: 1 } }),
    },
    {
      title: "Valor Desconto",
      width: "10%",
      dataIndex: "details",
      key: "details",
      render: (value, record) => (
        <Tag color="blue">
          {new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(
            (value || []).reduce(
              (prev: any, curr: any) => prev + curr?.value,
              0
            )
          )}
        </Tag>
      ),
    },
    {
      title: "Data Criação",
      dataIndex: "createdAt",
      key: "createdAt",
      onCell: () => ({ style: { flex: 1 } }),
      render: (value) => value && dayjs(value).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Data Última Alteração",
      dataIndex: "updatedAt",
      key: "updatedAt",
      onCell: () => ({ style: { flex: 1 } }),
      render: (value) => value && dayjs(value).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Usuário",
      dataIndex: "user",
      key: "user",
      onCell: () => ({ style: { flex: 1 } }),
      render: (value) => value?.name,
    },
    {
      title: "Situação",
      dataIndex: "finalized",
      key: "finalized",
      onCell: () => ({ style: { flex: 1 } }),
      render: (value, record) => (
        <Tag color={value ? "green" : "orange"}>
          {value ? "Finalizado" : "Em andamento"}
        </Tag>
      ),
    },
    {
      onCell: () => ({ style: { flex: 1 } }),
      render: (value, record: any) => (
        <span style={{ display: "flex", gap: "2px" }}>
          <ActionGrid
            handleEdit={handleEdit}
            handleDelete={() => {}}
            roles={roles}
            value={value}
          />
        </span>
      ),
    },
  ];

  return (
    <Container>
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <PageHeader
            title="Controle de Documentos"
            roles={roles}
            loading={loading}
            onClickAdd={handleAdd}
          />
          <Divider dashed />
          <SearchForm onFinish={onSearch} />
        </Col>

        <Col span={24}>
          <Divider dashed />
        </Col>
        <Col span={24}>
          <Table
            rowKey={"_id"}
            bordered
            loading={loading}
            dataSource={employeeDocuments}
            columns={[
              ...TableColumns(),
              {
                onCell: () => ({ style: { flex: 1 } }),
                render: (value, record: any) => (
                  <span style={{ display: "flex", gap: "2px" }}>
                    <ActionGrid
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                      roles={roles}
                      value={value}
                    />
                  </span>
                ),
              },
            ]}
            size="small"
            style={{ width: "100%", overflow: "scroll" }}
          />
        </Col>
      </Row>
    </Container>
  );
};
