import { Typography } from "antd";
import styled from "styled-components";

export const LabelWithButton = styled.span`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content:end;
  margin-inline-end:0 !important;
  margin-inline-start:0 !important;
  `;

export const TitleHeaderTable = styled.span`
  font-size: 10px;
`;
export const ContentHeaderTable = styled(Typography.Text)`
  font-size: 10px;
`;