import { Table } from "antd";
import { ContentHeaderTable, TitleHeaderTable } from "./style";
import { ActionGrid } from "../../components/action-grid";

interface TableAccidentsProps {
  handleEdit: (record: any) => void;
  handleDelete: (record: any) => void;
  dataSource: [
    {
      _id?: string;
      vehicle?: string;
      year?: number;
      licensePlate?: string;
    },
  ];
}
export const TableAccidents: React.FC<TableAccidentsProps> = ({
  dataSource,
  handleDelete,
  handleEdit,
}) => {
  
  return (
    <Table
      rowKey={"_id"}
      bordered
      pagination={false}
      dataSource={dataSource}
      size="small"
      style={{ width: "100%", fontSize: 8 }}
      columns={[
        {
          title: <TitleHeaderTable>Veículo</TitleHeaderTable>,
          dataIndex: "vehicle",
          key: "vehicle",
          render: (text: any, record: any) => (
            <ContentHeaderTable>{text}</ContentHeaderTable>
          ),
        },
        {
          title: <TitleHeaderTable>Ano</TitleHeaderTable>,
          dataIndex: "year",
          key: "year",
          render: (text: any, record: any) => (
            <ContentHeaderTable>{text}</ContentHeaderTable>
          ),
        },
        {
          title: <TitleHeaderTable>Placa</TitleHeaderTable>,
          dataIndex: "licensePlate",
          key: "licensePlate",
          render: (text: any, record: any) => (
            <ContentHeaderTable>{text}</ContentHeaderTable>
          ),
        },
        {
          render: (value, record) => (
            <ActionGrid
              handleEdit={handleEdit}
              handleDelete={()=>handleDelete({ content: record })}
              roles={{ create: true, edit: true, delete: true, show: true }}
              value={value}
            />
          ),
        },
      ]}
    />
  );
};
