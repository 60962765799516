import { Button, Col, Form, FormInstance, Input, Row, Select } from "antd";
import { FormContentButtons } from "../../pages/companies/style";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";

type SearchFormProp = {
  onFinish?: (values: any) => void;
  onReset?: () => void;
  data: any;
  form?: FormInstance<any>;
};
export const SearchForm: React.FC<SearchFormProp> = ({
  form,
  onFinish,
  onReset,
  data,
}) => {
  return (
    <div
      style={{
        border: "1px solid #c6c66c6",
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ minWidth: "100%" }}
      >
        <Row gutter={[8, 2]}>
          <Col span={9}>
            <Form.Item label="Nome" name={"fullname"}>
              <Input placeholder="Nome" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="CPF" name={"code"}>
              <Input placeholder="CPF" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="RG" name={"registerNumber"}>
              <Input placeholder="RG" />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item label="Cargo" name={"position"}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  String(option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Selecione o Cargo"
                style={{ width: "100%" }}
                options={data?.position?.flatMap((e: any) => [
                  { value: e?._id, label: e?.name },
                ])}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Associado" name={"associate"}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  String(option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Selecione o Associado"
                style={{ width: "100%" }}
                options={data?.associates?.flatMap((e: any) => [
                  { value: e?._id, label: e?.name },
                ])}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <FormContentButtons>
              <Form.Item>
                <Button
                  type="default"
                  icon={<ClearOutlined />}
                  onClick={onReset}
                >
                  Limpar
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  Pesquisar
                </Button>
              </Form.Item>
            </FormContentButtons>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
