import { Button, Col, DatePicker, Form, FormInstance, Input, Row } from "antd";
import { FormContentButtons } from "../../pages/companies/style";
import { MaskedInput } from "antd-mask-input";
import { useForm } from "antd/lib/form/Form";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";

type SearchFormProp = {
  onFinish?: (values: any) => void;
  onReset?: () => void;
  data: any;
  form?: FormInstance<any>;
};
export const SearchForm: React.FC<SearchFormProp> = ({
  form,
  onFinish,
  onReset,
  data,
}) => {
  return (
    <div
      style={{
        border: "1px solid #c6c66c6",
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ minWidth: "100%" }}
      >
        <Row gutter={[8, 2]}>
          <Col span={5}>
            <Form.Item label="Data Inicío" name={"startDate"}>
              <DatePicker
                placeholder="Data Inicío"
                format={"DD/MM/YYYY"}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Data Fim" name={"endDate"}>
              <DatePicker
                placeholder="Data Fim"
                format={"DD/MM/YYYY"}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <FormContentButtons>
              <Form.Item>
                <Button
                  type="default"
                  icon={<ClearOutlined />}
                  onClick={onReset}
                >
                  Limpar
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  Pesquisar
                </Button>
              </Form.Item>
            </FormContentButtons>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
