import { Tag } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

export const TableColumns = () => {
  const pp: any = [
    {
      title: "Empresa",
      dataIndex: "employee",
      fixed: "left",
      onCell: () => ({ style: { flex: 1 } }),
      render: (value: any) => value?.associate?.name ?? "",
    },
    {
      title: "Nome",
      dataIndex: "employee",
      fixed: "left",
      onCell: () => ({ style: { flex: 1 } }),
      render: (value: any) => value?.fullname ?? "",
    },
    {
      title: "Cargo",
      dataIndex: "employee",
      fixed: "left",
      onCell: () => ({ style: { flex: 1 } }),
      render: (value: any) => value?.position?.description ?? "",
    },
    {
      title: "ASO",
      onCell: () => ({ style: { flex: 1 } }),
      textAlign: "center",
      children: [
        {
          title: "Data Exame",
          dataIndex: "asoStartDate",
          editable: true,
          align: "center",

          type: "date",
          onCell: () => ({ style: { flex: 1 } }),
          render: (value: any) =>
            value ? dayjs(value).format("DD/MM/YYYY") : "-",
        },
        {
          title: "Data de Validade",
          dataIndex: "asoEndDate",
          //editable: true,
          type: "date",
          onCell: () => ({ style: { flex: 1 } }),
          render: (value: any, record: any) =>
            value ? (
              <Tag color={record?.expiredAso ? "red" : "blue"}>
                {dayjs(value).format("DD/MM/YYYY")}
              </Tag>
            ) : (
              "-"
            ),
        },
      ],
    },
    {
      title: "CNH",
      onCell: () => ({ style: { flex: 1 } }),
      children: [
        {
          title: "Data de Validade",
          dataIndex: "cnhEndDate",
          type: "date",
          onCell: () => ({ style: { flex: 1 } }),
          render: (value: any, record: any) =>
            value ? (
              <Tag color={record?.expiredCnh ? "red" : "blue"}>
                {dayjs(value).format("DD/MM/YYYY")}
              </Tag>
            ) : (
              "-"
            ),
        },
      ],
    },
    {
      title: "EPI",
      onCell: () => ({ style: { flex: 1 } }),
      children: [
        {
          title: "Número",
          dataIndex: "number",
          //editable: true,
          type: "text",
          onCell: () => ({ style: { flex: 1 } }),
          render: (value: any) =>
            value ? dayjs(value).format("DD/MM/YYYY") : "-",
        },
        {
          title: "Data de Validade",
          dataIndex: "epiEndDate",
          type: "date",
          //editable: true,
          onCell: () => ({ style: { flex: 1 } }),
          render: (value: any, record: any) =>
            value ? (
              <Tag color={record?.expiredEpi ? "red" : "blue"}>
                {dayjs(value).format("DD/MM/YYYY")}
              </Tag>
            ) : (
              "-"
            ),
        },
      ],
    },
  ];

  return pp;
};
