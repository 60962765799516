import { Container } from "../../components/container";
import { api } from "../../api/api";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import Drawer from "../../components/drawer";
import { Col, Divider, Row, Table } from "antd";
import { useSecurity } from "../../hooks/useSecurity";
import { ActionGrid } from "../../components/action-grid";
import { PageHeader } from "../../components/page-header";
import { SearchForm } from "../../forms/paymentTypes/SearchForm";
import { CreateOrEditForm } from "../../forms/paymentTypes/CreateOrEditForm";

export const PaymentTypes: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [paymentType, setPaymentType] = useState<any>({});
  const [paymentTypes, setPaymentTypes] = useState<any[]>([]);
  const [roles, setRoles] = useState<any>();

  const { getPermissions } = useSecurity();

  const getData = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/paymentTypes`)))
      .then(({ data }: any) => setPaymentTypes(data))
      .finally(() => setLoading(false));
  };

  const handleAdd = () => {
    setAction("add");
    setPaymentType({});
    setOpen(true);
  };

  const handleEdit = ({ data }: any) => {
    setAction("edit");
    setPaymentType(data);
    setOpen(true);
  };

  const handleDelete = ({ content }: any) => {
    new Promise((resolve, _) =>
      resolve(api.delete(`/paymentTypes/${content?._id}`))
    ).then(() => new Promise((resolve, _) => resolve(getData())));
  };

  const onSubmit = (content: any) => {
    new Promise((resolve, _) => {
      if (content?._id) {
        resolve(api.put(`/paymentTypes/${content?._id}`, content));
      } else {
        resolve(api.post(`/paymentTypes`, content));
      }
    }).then(() => {
      new Promise((resolve, _) => resolve(getData()));
      setPaymentType({});
      setOpen(false);
    });
  };

  const onSearch = (content: any) => {
    setLoading(true);

    let body = {};
    for (const key in content || {}) {
      if (content[key] !== undefined) {
        body = {
          ...body,
          [key]: content[key],
        };
      }
    }
    const queryString = new URLSearchParams(body).toString();

    if (String(queryString).length !== 0) {
      new Promise((resolve, _) =>
        resolve(api.get(`/paymentTypes?${queryString}`))
      )
        .then(({ data }: any) => setPaymentTypes(data))
        .finally(() => setLoading(false));
    } else {
      new Promise((resolve, _) => resolve(api.get(`/paymentTypes`)))
        .then(({ data }: any) => setPaymentTypes(data))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    new Promise((resolve, _) => resolve(getData()));
  }, []);

  useEffect(() => {
    const role = getPermissions({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissions]);

  const columns: ColumnsType<[]> = [
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      render: (value, record) => (
        <ActionGrid
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          roles={roles}
          value={value}
        />
      ),
    },
  ];

  return (
    <Container>
      <Row>
        <Col span={24}>
          <PageHeader
            title="Forma de Pagamento"
            onClickAdd={handleAdd}
            roles={roles}
          />
          <SearchForm onFinish={onSearch} />
        </Col>

        <Col span={24}>
          <Divider dashed />
        </Col>
        <Col span={24}>
          <Table
            rowKey={"_id"}
            bordered
            loading={loading}
            dataSource={paymentTypes}
            columns={columns}
            size="small"
            style={{ width: "100%", overflow: "scroll" }}
          />
        </Col>
      </Row>

      <Drawer
        open={open}
        title={action === "add" ? "Adicionar" : "Editar"}
        destroyOnClose={true}
        onClose={() => setOpen(false)}
        children={<CreateOrEditForm onFinish={onSubmit} data={paymentType} />}
      />
    </Container>
  );
};
