import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: yellow;
`;

export const ErrorHeaderBox = styled.div`
  background-color: var(--codar-bg-950);
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ErrorCode = styled.div`
  color: var(--codar-warning-500);
  text-align: center;

  h1 {
    font-size: 80px;
  }
  h4 {
    font-size: 40px;
  }
`;
export const ErrorIcon = styled.div`
  color: var(--codar-warning-500);
`;
export const ErrorContentBox = styled.div`
  background-color: var(--codar-bg-200);
  height: 50vh;
  text-align: center;
  padding: 20px;
  color: var(--codar-warning-900);
`;
