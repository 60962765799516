import { SaveOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { STATUS } from "../../constants/status";

type CreateOrEditForm = {
  onFinish?: (values: any) => void;
  data: any;
};
export const CreateOrEditForm: React.FC<CreateOrEditForm> = ({
  onFinish,
  data,
}) => {
  const [form] = useForm();
  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  return (
    <Form
      form={form}
      // layout="vertical"
      labelCol={{ span: 4 }}
      // style={{ maxWidth: 600 }}
      onFinish={onFinish}
    >
      <Form.Item label="Id" name={"_id"} hidden>
        <Input placeholder="Identificador" />
      </Form.Item>
      <Form.Item label="Descrição" name={"description"}>
        <Input placeholder="Descrição" />
      </Form.Item>

      <Form.Item label="Situação" name={"status"}>
        <Select showSearch
              filterOption={(input, option) =>
                String(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              } 
          style={{ width: "100%" }}
          options={STATUS}
        />
      </Form.Item>

      <Form.Item>
        <Flex justify="end">
          <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
            Salvar
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};
