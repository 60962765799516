import React, { ReactNode, createContext, useEffect, useState } from "react";
import { api } from "../api/api";
import { jwtDecode } from "jwt-decode";

type AuthContextProp = {
  user?: any;
  loadingSession: boolean;
  destroySession: () => void;
  createSession: (data: any) => void;
};
type AuthProviderProp = {
  children: ReactNode;
};

export const AuthContext = createContext<AuthContextProp>(
  {} as AuthContextProp
);

export const AuthProvider: React.FC<AuthProviderProp> = ({ children }) => {
  const [loadingSession, setLoadingSession] = useState<boolean>(true);
  const [user, setUser] = useState<any>();
  const [token, setToken] = useState<any>();

  const createSession = async (data: any) => {
    const resp = await api
      .post(`/auth/login`, data)
      .then(({ data }) => data)
      .catch(({ response: { data } }) => {
        return data;
      });

    if (resp?.access_token) {
      const { access_token, ...rest } = resp;
      localStorage.setItem("@Codar:user", JSON.stringify(rest));
      localStorage.setItem("@Codar:token", access_token);
      api.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
      setUser(data);
      return { statusCode: 200 };
    }
    return resp;
  };

  const destroySession = () => {
    if (localStorage.getItem("@Codar:user")) {
      setUser(undefined);
      setToken(undefined);
      localStorage.removeItem("@Codar:user");
      localStorage.removeItem("@Codar:token");
      window.location.reload();
    }
  };
  const getSession = async () => {
    const token = localStorage.getItem("@Codar:token");
    const user = localStorage.getItem("@Codar:user");
    if (user && user.length > 0 && token && token.length > 0) {
      try {
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        // const { data } = await apiUserInfo();
        setUser(user);
        setToken(token);
      } catch (error) {
        // destrtoy a sessao
        destroySession();
      }
    }
    setLoadingSession(false);
  };

  useEffect(() => {
    getSession();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        loadingSession,
        createSession,
        destroySession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
