import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;

  @media only screen and (max-device-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Logo = styled.div`
  background-color: var(--codar-bg-50);
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: var(--codar-bg-900);
    /* font-size: 120px; */
  }
  img {
    width: 350px;
  }

  @media only screen and (max-device-width: 480px) {
    display: flex;
    width: 100%;
    height: 40%;
    h1 {
      color: var(--codar-bg-900);
      font-size: 50px;
    }

    img {
      width: 100px;
    }
  }
`;
export const LoginForm = styled.div`
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: var(--codar-bg-900);
    display: block;
    padding: 10px;
  }
  form {
    border-radius: 5px;
    box-shadow: 2px 2px 50px var(--codar-bg-100);
    background-color: var(--codar-bg-100);
    padding: 50px;
    width: 450px;
  }
  .ant-form-item-label label {
    color: var(--codar-bg-900);
    font-weight: bold;
  }
  .ant-btn {
    background: linear-gradient(var(--codar-bg-700), var(--codar-bg-800));
    color: var(--codar-bg-50) !important;
    border: none;
  }
  .ant-btn:hover {
    background: linear-gradient(
      var(--codar-bg-600),
      var(--codar-bg-700)
    ) !important;
    color: #fff !important;
    border: none !important;
  }

  @media only screen and (max-device-width: 480px) {
    width: 100%;
    height: 100%;

    .ant-btn {
      background: linear-gradient(var(--codar-bg-700), var(--codar-bg-800));
      color: #fff;
      border: none;
      display: block;
      width: 100%;
      height: 56px;
    }
  }
`;
