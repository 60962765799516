import { Col, Divider, Row, Table } from "antd";
import { Container } from "../../components/container";
import { useEffect, useState } from "react";
import { api } from "../../api/api";
import { ColumnsType } from "antd/es/table";
import Drawer from "../../components/drawer";
import { SearchForm } from "../../forms/suppliers/SearchForm";
import { CreateOrEditForm } from "../../forms/suppliers/CreateOrEditForm";
import { ActionGrid } from "../../components/action-grid";
import { useSecurity } from "../../hooks/useSecurity";
import { PageHeader } from "../../components/page-header";

export const Suppliers: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [company, setCompany] = useState({});
  const [suppliers, setCompanies] = useState<any[]>([]);
  const [roles, setRoles] = useState<any>();

  const { getPermissions } = useSecurity();

  const getData = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/supplier`)))
      .then(({ data }: any) => setCompanies(data))
      .finally(() => setLoading(false));
  };

  const handleAdd = () => {
    setAction("add");
    setCompany({});
    setOpen(true);
  };

  const handleEdit = ({ data }: any) => {
    setAction("edit");
    setCompany(data);
    setOpen(true);
  };

  const handleDelete = ({ content }: any) => {
    new Promise((resolve, _) =>
      resolve(api.delete(`/supplier/${content?._id}`))
    ).then(() => new Promise((resolve, _) => resolve(getData())));
  };

  const onSubmit = (content: any) => {
    new Promise((resolve, _) => {
      if (content?._id) {
        resolve(api.put(`/supplier/${content?._id}`, content));
      } else {
        resolve(api.post(`/supplier`, content));
      }
    }).then(() => {
      new Promise((resolve, _) => resolve(getData()));
      setCompany({});
      setOpen(false);
    });
  };

  const onSearch = (content: any) => {
    setLoading(true);

    let body = {};
    for (const key in content || {}) {
      if (content[key] !== undefined) {
        body = {
          ...body,
          [key]: content[key],
        };
      }
    }
    const queryString = new URLSearchParams(body).toString();

    if (String(queryString).length !== 0) {
      new Promise((resolve, _) => resolve(api.get(`/supplier?${queryString}`)))
        .then(({ data }: any) => setCompanies(data))
        .finally(() => setLoading(false));
    } else {
      new Promise((resolve, _) => resolve(api.get(`/supplier`)))
        .then(({ data }: any) => setCompanies(data))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    new Promise((resolve, _) => resolve(getData()));
  }, []);

  useEffect(() => {
    const role = getPermissions({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissions]);

  const columns: ColumnsType<[]> = [
    {
      title: "Razão Social",
      dataIndex: "name",
      key: "name",
    },
    { title: "Cnpj", dataIndex: "code", key: "code" },
    { title: "Tel", dataIndex: "phone", key: "phone" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Site", dataIndex: "website", key: "website" },
    { title: "Situação", dataIndex: "status", key: "status" },
    {
      render: (value, record) => (
        <ActionGrid
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          roles={roles}
          value={value}
        />
      ),
    },
  ];

  return (
    <Container>
      <Row>
        <Col span={24}>
          <PageHeader
            title="Fornecedores"
            onClickAdd={handleAdd}
            roles={roles}
          />
          <SearchForm onFinish={onSearch} />
        </Col>

        <Col span={24}>
          <Divider dashed />
        </Col>
        <Col span={24}>
          <Table
            rowKey={"_id"}
            bordered
            loading={loading}
            dataSource={suppliers}
            columns={columns}
            size="small"
            style={{ width: "100%", overflow: "scroll" }}
          />
        </Col>
      </Row>

      <Drawer
        open={open}
        title={action === "add" ? "Adicionar" : "Editar"}
        destroyOnClose={true}
        onClose={() => setOpen(false)}
        children={<CreateOrEditForm onFinish={onSubmit} data={company} />}
      />
    </Container>
  );
};
