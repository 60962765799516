import dayjs from "dayjs";
import { useEffect } from "react";
import { useForm } from "antd/lib/form/Form";
import { SaveOutlined } from "@ant-design/icons";
import { NumericFormat } from "react-number-format";
import { Button, Col, DatePicker, Flex, Form, Input, Row, Select } from "antd";

interface BillingForm {
  data: any;
  onFinish?: (values: any) => void;
}
export const BillingForm: React.FC<BillingForm> = ({ data,onFinish }) => {
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...data?.detail,
      ...(data?.detail?.date ? {date: dayjs(data?.detail?.date, "YYYY-MM-DD")}:{}),
      ...(data?.detail?.supplier?._id?{supplier: data?.detail?.supplier?._id}:{}),
    });
  }, [data]);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={[8, 2]}>
        <Col span={24}>
          <Form.Item label="Id" name={"_id"} hidden>
            <Input placeholder="Identificador" />
          </Form.Item>
          <Form.Item label="Associado" name={"associateMonthlyBilling"}hidden >
            <Input placeholder="Associado" />
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item label="Data" name={"date"}>
            <DatePicker
              format="DD/MM/YYYY"
              placeholder="Data"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item label="Fornecedor" name={"supplier"} rules={[{ required: true }]}>
            <Select
              placeholder="Fornecedor"
              showSearch
              onChange={(value, option: any) => {}}
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={data?.suppliers?.flatMap((e: any) => [
                { value: e?._id, label: e?.name },
              ])}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Valor" name={"value"} rules={[{ required: true }]}>
            <NumericFormat
              displayType="input"
              thousandSeparator={"."}
              decimalScale={2}
              prefix="R$"
              decimalSeparator=","
              customInput={Input}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Flex justify="end">
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Salvar
              </Button>
            </Flex>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
