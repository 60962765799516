import { DatePicker, Typography } from "antd";
import styled from "styled-components";


export const Title = styled(Typography.Text)`
  color: var(--codar-bg-950);
  font-weight: bold;
  font-size: 18px;
`;

export const RangePicker = styled(DatePicker.RangePicker)``;
export const Action = styled.div`
  display: flex;
  gap: 2px;
`;
