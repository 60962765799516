import React, { useEffect, useState } from "react";
import { Container, ContainerPage } from "./style";
import {
  Button,
  Col,
  Flex,
  Form,
  Image,
  Input,
  message,
  Row,
  Spin,
  Typography,
  Upload,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useSecurity } from "../../hooks/useSecurity";
import { api } from "../../api/api";

interface IMyProfile {}
export const MyProfile: React.FC<IMyProfile> = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { userInfo } = useSecurity();

  const changeAvatar = async (info: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", info);

    api
      .post("/users/changeAvatar", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .catch(() => message.error("Erro ao alterar avatar. Tente novamente"))
      .finally(() => setLoading(false));
  };

  const onFinish = async (info: any) => {
    const { password } = info;

    if (String(password).length > 0) {
      api
        .post(
          "/users/changePassword",
          { password },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => message.success("Dados atualizados com sucesso"))
        .catch(() => message.error("Erro ao alterar avatar. Tente novamente"));
    }
  };

  useEffect(() => {
    form.setFieldsValue(userInfo);
  }, []);

  return (
    <ContainerPage>
      <Spin spinning={loading} tip="Carregando...">
        <Row gutter={[8, 2]}>
          <Col span={24}>
            <Typography.Title level={2}>Minha Conta</Typography.Title>
          </Col>
          <Col span={12} style={{ padding: 0 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
                // backgroundColor: "var(--codar-bg-900)",
                height: "100%",
                width: "100%",
              }}
            >
              <Image
                width={200}
                style={{ borderRadius: 100 }}
                src={
                  form.getFieldValue("avatar") ||
                  "https://avatars.githubusercontent.com/u/10126099?v=4"
                }
                preview={true}
              />
              <Upload
                accept="image/*"
                maxCount={1}
                showUploadList={false}
                name="file"
                customRequest={({ file, onSuccess, onError }) =>
                  changeAvatar(file).then(onSuccess).catch(onError)
                }
              >
                <Typography.Link type="secondary">Trocar Foto</Typography.Link>
              </Upload>
            </div>
          </Col>
          <Col span={12}>
            <Container>
              <Form labelCol={{ span: 4 }} form={form} onFinish={onFinish}>
                <Row gutter={[8, 2]}>
                  <Col span={24}>
                    <Form.Item label="Nome" name={"name"}>
                      <Input placeholder="Nome" disabled />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Email" name={"email"}>
                      <Input placeholder="Email" disabled />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Senha" name={"password"}>
                      <Input
                        placeholder="Senha"
                        type="password"
                        autoComplete=""
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Flex justify="end">
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          icon={<SaveOutlined />}
                        >
                          Salvar
                        </Button>
                      </Form.Item>
                    </Flex>
                  </Col>
                </Row>
              </Form>
            </Container>
          </Col>
          <Col span={24}></Col>
        </Row>
      </Spin>
    </ContainerPage>
  );
};
