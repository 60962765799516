import React, {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../hooks/useAuth";
import { api } from "../api/api";

type SecurityContextProp = {
  getDecryptedToken: () => any;
  getPermissions: ({ route }: { route: string }) => any;
  userInfo: any;
};
type AuthProviderProp = {
  children: ReactNode;
};

export const SecurityContext = createContext<SecurityContextProp>(
  {} as SecurityContextProp
);

export const SecurityProvider: React.FC<AuthProviderProp> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<any>();
  const [decryptedToken, setDecryptedToken] = useState<any>("");
  const { destroySession } = useAuth();

  const getDecryptedToken = () => {
    const token = localStorage.getItem("@Codar:token");
    try {
      if (token) {
        const jwtDecoded = jwtDecode(token);
        setDecryptedToken(jwtDecoded);
        setUserInfo(jwtDecoded);
        return jwtDecoded;
      }
    } catch (error) {
      destroySession();
      return null;
    }
  };

  const getPermissions = ({ route }: { route: string }) => {
    const permission = decryptedToken?.permissions.find(
      (e: any) => e.route === route
    );
    return {
      show: permission?.show,
      create: permission?.create,
      edit: permission?.edit,
      delete: permission?.delete,
    };
  };

  const checkToken = useCallback(() => {
    const token = localStorage.getItem("@Codar:token");
    if (!token || String(token).length === 0) {
      destroySession();
      return null;
    }

    // validar token
    api.get("/session/checkSession").catch((error) => {
      destroySession();
    });
  }, [userInfo]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     checkToken();
  //   }, 5000); // 60000 ms = 1 minuto

  //   // Cleanup do intervalo ao desmontar o componente
  //   return () => clearInterval(interval);
  // }, [checkToken]);

  useEffect(() => {
    getDecryptedToken();
  }, []);

  return (
    <SecurityContext.Provider
      value={{
        userInfo,
        getDecryptedToken,
        getPermissions,
      }}
    >
      {children}
    </SecurityContext.Provider>
  );
};
