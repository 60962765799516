import { SaveOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { STATUS } from "../../constants/status";
import { NumericFormat } from "react-number-format";
import { DiscountType } from "../../enum/EDiscountType";

type CreateOrEditForm = {
  onFinish?: (values: any) => void;
  data: any;
};
export const CreateOrEditForm: React.FC<CreateOrEditForm> = ({
  onFinish,
  data,
}) => {
  const [typeDiscount, setTypeDiscount] = useState();

  const [form] = useForm();
  useEffect(() => {
    form.setFieldsValue(data);
    setTypeDiscount(data?.typeDiscount);
  }, [data, form]);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={[8, 2]}>
        <Col span={24}>
          <Form.Item label="Id" name={"_id"} hidden>
            <Input placeholder="Identificador" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Código" name={"code"}>
            <Input placeholder="Código" />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item label="Descrição" name={"description"}>
            <Input placeholder="Descrição" />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item label="Aplicar Desconto Fixo" name={"fixedDiscount"}>
            <Select
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={[
                { value: true, label: "Sim" },
                { value: false, label: "Não" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item label="Tipo do Desconto" name={"typeDiscount"}>
            <Select
              onChange={(e: any) => {
                setTypeDiscount(e);
              }}
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={Object.keys(DiscountType).map((e: any) => ({
                label: DiscountType[e],
                value: e,
              }))}
            />
          </Form.Item>
        </Col>
        {(data?._id || ["FIXED", "PERCENTAGE"].includes(typeDiscount!)) && (
          <Col span={16}>
            <Form.Item label="Valor do Desconto" name={"valueDiscount"}>
              {typeDiscount === "FIXED" && (
                <NumericFormat
                  displayType="input"
                  thousandSeparator={"."}
                  decimalScale={2}
                  decimalSeparator=","
                  prefix="R$"
                  customInput={Input}
                />
              )}
              {typeDiscount === "PERCENTAGE" && (
                <NumericFormat
                  displayType="input"
                  decimalScale={2}
                  decimalSeparator=","
                  prefix=""
                  customInput={Input}
                />
              )}
            </Form.Item>
          </Col>
        )}

        <Col span={8}>
          <Form.Item label="Situação" name={"status"}>
            <Select
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={STATUS}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Flex justify="end">
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Salvar
              </Button>
            </Flex>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
