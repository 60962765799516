import { Button, Popconfirm, Tooltip } from "antd";
import { GroupButton } from "./style";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export interface IActionGrid {
  handleEdit: ({ data }: any) => void;
  handleDelete: ({ content }: any) => void;
  value: any;
  roles: {
    show: boolean;
    create: boolean;
    edit: boolean;
    delete: boolean;
  };
  children?: React.ReactNode;
  onlyIcon?: boolean;
}
export const ActionGrid: React.FC<IActionGrid> = ({
  handleDelete,
  handleEdit,
  roles,
  value,
  children,
  onlyIcon = false,
}) => {
  return (
    <GroupButton>
      <Tooltip title="Editar">
        {roles?.edit && (
          <Button
            size="small"
            icon={<EditOutlined />}
            onClick={() => handleEdit({ data: value })}
          >
            {!onlyIcon && "Editar"}
          </Button>
        )}
      </Tooltip>
      <Tooltip title="Excluir">
        <Popconfirm
          title="Tem certeza que deseja excluir?"
          onConfirm={() => handleDelete({ content: value })}
          okText="Sim"
          cancelText="Não"
        >
          {roles?.delete && (
            <Button size="small" icon={<DeleteOutlined />}>
              {!onlyIcon && "Excluir"}
            </Button>
          )}
        </Popconfirm>
      </Tooltip>
      {children}
    </GroupButton>
  );
};
