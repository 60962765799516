import { Container, LoginForm, Logo } from "./style";
import logo from "../../../assets/splash.png";
import { Button, Flex, Form, Input, message, Typography } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useAuth } from "../../../hooks/useAuth";
import { useState } from "react";
import { application } from "../../../constants/application/app";
import { useDrawer } from "../../../hooks/useDrawer";
import { ResetPassword } from "../resetPassword";
import { api } from "../../../api/api";

export const Login: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const { createSession } = useAuth();
  const { setTitle, setContent, setIsOpen, setClose } = useDrawer();

  const onResetPassword = (values: any) => {
    setLoading(true);
    api
      .post("/auth/resetPassword", values)
      .then(({ data }) => {
        message.success("Senha alterada com sucesso");
      })
      .catch(({ response }) => {
        message.error("Ocorreu um erro ao alterar a senha. Tente novamente");
      })
      .finally(() => {
        setLoading(false);
        setIsOpen(false);
      });
  };

  const handleOpenDrawer = () => {
    setTitle("Alterar Senha");
    setContent(<ResetPassword onFinish={onResetPassword} loading={loading} />);
    setIsOpen(true);
  };

  const onFinish = async (data: any) => {
    setLoading(true);
    const result: any = await createSession(data);
    if (String(result?.statusCode).includes("4")) {
      message.error("Usuário ou senha inválidos");
    }
    setLoading(false);
  };

  return (
    <Container>
      <Logo>
        <img src={logo} />
        <h1>{application?.companyName || ""}</h1>
      </Logo>
      <LoginForm>
        <h1>Faça seu login</h1>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <FormItem label={"Usuário"} name={"username"}>
            <Input />
          </FormItem>
          <FormItem label={"Senha"} name={"password"}>
            <Input type="password" />
          </FormItem>
          <Button type="default" block htmlType="submit" loading={loading}>
            Entrar
          </Button>
          <Flex justify="end" style={{ marginTop: "10px" }}>
            <Typography.Link onClick={handleOpenDrawer}>
              Esqueci minha senha
            </Typography.Link>
          </Flex>
        </Form>
      </LoginForm>
    </Container>
  );
};
