import { Drawer as _Drawer } from "antd";
import React, { ReactNode } from "react";

type DrawerProp = {
  destroyOnClose?: boolean;
  onClose: () => void;
  title?: string;
  open: boolean;
  children: ReactNode;
  size?:"large" | "default"
  width?:number

};
const Drawer: React.FC<DrawerProp> = (prop) => <_Drawer {...prop} {...prop?.size ? {size:prop.size}:{size:"large"}} width={prop?.width || 720} />;
export default Drawer;
