import { Button, Form, Input } from "antd";
import { FormContentButtons } from "../../pages/companies/style";
import { MaskedInput } from "antd-mask-input";
import { useForm } from "antd/lib/form/Form";
import { SearchOutlined } from "@ant-design/icons";

type SearchFormProp = {
  onFinish?: (values: any) => void;
};
export const SearchForm: React.FC<SearchFormProp> = ({ onFinish }) => {
  const [form] = useForm();

  return (
    <div
      style={{
        border: "1px solid #c6c66c6",
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ minWidth: "100%" }}
      >
        <Form.Item label="Razão Social" name={"name"}>
          <Input placeholder="Razão Social" />
        </Form.Item>
        <Form.Item label="Cnpj" name={"code"}>
          <MaskedInput mask={/^\d+$/gm} maxLength={14} />
        </Form.Item>

        <FormContentButtons>
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              Pesquisar
            </Button>
          </Form.Item>
        </FormContentButtons>
      </Form>
    </div>
  );
};
