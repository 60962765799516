import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const GuardPrivateRoutes = () => {
  const { user, loadingSession } = useAuth();

  if (loadingSession) {
    return <></>;
  }

  return !!user ? <Outlet /> : <Navigate to="/p/login" />;
};
