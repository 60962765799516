import styled from "styled-components";

export const ContainerPage= styled.div`
  padding: 24px;
`;

export const Wrapper = styled.div`
width: 100%;
height: 100% !important;
/* padding: 5px; */
`;

export const Container = styled.div`
width: 100%;
height: 100%;
margin-top:55px;
padding-right: 10px;
`;