import { SaveOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Form, Input, Row, Select, Transfer } from "antd";
import { TransferProps } from "antd/lib";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { STATUS } from "../../constants/status";
import { YES_NO } from "../../constants/yes-no";

type CreateOrEditForm = {
  onFinish?: (values: any) => void;
  data: any;
};
type RecordType = {
  key: string;
  title: string;
  description: string;
  chosen: boolean;
};

export const CreateOrEditForm: React.FC<CreateOrEditForm> = ({
  onFinish,
  data,
}) => {
  const [form] = useForm();
  const [vehicles, setVehicles] = useState<RecordType[]>([]);
  const [targetKeys, setTargetKeys] = useState<TransferProps["targetKeys"]>([]);

  const filterOption = (inputValue: string, option: RecordType) =>
    option.description.indexOf(inputValue) > -1;

  const handleChange: TransferProps["onChange"] = (newTargetKeys) => {
    setTargetKeys(newTargetKeys);
  };

  useEffect(() => {
    form.setFieldsValue({
      ...data?.associate,
      ...(data?.associate?.vehicles?._id
        ? { vehicles: data?.associate?.vehicles.flatMap((e: any) => e?._id) }
        : {}),
    });
  }, []);

  useEffect(() => {
    const currentTarget: any = [];
    setVehicles(
      data?.vehicles.map((e: any) => {
        // rotina para preencher os veiculos ja salvos
        if (data?.associate?.vehicles?.find((j: any) => j._id === e?._id)) {
          currentTarget.push(e?._id);
        }

        return {
          key: e?._id,
          title: e?.plate,
          description: e?.plate,
        };
      })
    );

    setTargetKeys(currentTarget);
  }, [data?.vehicles]);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={[8, 2]}>
        <Col span={24}>
          <Form.Item label="Id" name={"_id"} hidden>
            <Input placeholder="Identificador" />
          </Form.Item>
        </Col>

        <Col span={18}>
          <Form.Item label="Razão Social" name={"name"}>
            <Input placeholder="Razão Social" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Cnpj" name={"code"}>
            <Input placeholder="Cnpj" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Endereço" name={"address"}>
            <Input placeholder="Endereço" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Telefone" name={"phone"}>
            <Input placeholder="Telefone" />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item label="Email" name={"email"}>
            <Input placeholder="Email" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Site" name={"website"}>
            <Input placeholder="Site" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={"Veículos"} name={"vehicles"}>
            <Transfer
              dataSource={vehicles}
              showSearch
              filterOption={filterOption}
              targetKeys={targetKeys}
              onChange={handleChange}
              render={(item) => item.description}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Permitir Receber Notificações por Email"
            name={"receiveNotificationsEmail"}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={YES_NO}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Situação" name={"status"}>
            <Select
              showSearch
              filterOption={(input, option) =>
                String(option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: "100%" }}
              options={STATUS}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Flex justify="end">
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Salvar
              </Button>
            </Flex>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
