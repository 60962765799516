import { Button, Col, Form, Input, Row, Select } from "antd";
import { FormContentButtons } from "../../../pages/companies/style";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

interface SearchFormFieldsProps {
  data?: any;
  onReset?: () => void;
}
export const SearchFormFields = ({ data, onReset }: SearchFormFieldsProps) => (
  <Row gutter={[8, 2]}>
    <Col span={9}>
      <Form.Item label="Nome" name={"fullname"}>
        <Input placeholder="Nome" />
      </Form.Item>
    </Col>
    <Col span={5}>
      <Form.Item label="CPF" name={"code"}>
        <Input placeholder="CPF" />
      </Form.Item>
    </Col>
    <Col span={5}>
      <Form.Item label="RG" name={"registerNumber"}>
        <Input placeholder="RG" />
      </Form.Item>
    </Col>
    
    <Col span={5}>
      <Form.Item label="Cargo" name={"position"}>
      <Select
          showSearch
          filterOption={(input, option) =>
            String(option?.label ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          placeholder="Selecione o Cargo"
          style={{ width: "100%" }}
          options={data?.position?.flatMap((e: any) => [
            { value: e?._id, label: e?.name },
          ])}
        />
      </Form.Item>
    </Col>
    <Col span={24}>
      <Form.Item label="Associado" name={"associate"}>
        <Select
          showSearch
          filterOption={(input, option) =>
            String(option?.label ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          placeholder="Selecione o Associado"
          style={{ width: "100%" }}
          options={data?.associates?.flatMap((e: any) => [
            { value: e?._id, label: e?.name },
          ])}
        />
      </Form.Item>
    </Col>
    <Col span={24}>
      <FormContentButtons>
        <Form.Item>
          <Button type="default" icon={<ClearOutlined />} onClick={onReset}>
            Limpar
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
            Pesquisar
          </Button>
        </Form.Item>
      </FormContentButtons>
    </Col>
  </Row>
);

export const columnsTable: ColumnsType<[]> = [
  {
    title: "Nome",
    dataIndex: "fullname",
    key: "fullname",
  },

  {
    title: "CPF",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "RG",
    dataIndex: "registerNumber",
    key: "registerNumber",
  },
  {
    title: "Cargo",
    dataIndex: "jobDescription",
    key: "jobDescription",
  },
  {
    title: "Telefone",
    dataIndex: "cellPhone",
    key: "cellPhone",
  },
  {
    title: "Associado",
    dataIndex: "associate",
    key: "associate",
    render: (value: any) => value?.name ?? "",
  },
];
