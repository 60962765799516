import { Table } from "antd";
import { ContentHeaderTable, TitleHeaderTable } from "./style";
import { ActionGrid } from "../../components/action-grid";

interface TableBudgetProps {
  handleEdit: (record: any) => void;
  handleDelete: (record: any) => void;
  dataSource: [
    {
      _id?: string;
      vehicle?: string;
      year?: number;
      licensePlate?: string;
    },
  ];
}
export const TableBudget: React.FC<TableBudgetProps> = ({
  dataSource,
  handleDelete,
  handleEdit,
}) => {
  console.log("dataSource", dataSource);
  
  return (
    <Table
      rowKey={"_id"}
      bordered
      pagination={false}
      dataSource={dataSource}
      size="small"
      style={{ width: "100%", fontSize: 8 }}
      columns={[
        {
          title: <TitleHeaderTable>Oficina</TitleHeaderTable>,
          dataIndex: "workshop",
          key: "workshop",
          render: (text: any, record: any) => (
            <ContentHeaderTable>{text}</ContentHeaderTable>
          ),
        },
        {
          title: <TitleHeaderTable>Valor</TitleHeaderTable>,
          dataIndex: "value",
          key: "value",
          render: (text: any, record: any) => (
            <ContentHeaderTable>{text}</ContentHeaderTable>
          ),
        },
        {
          title: <TitleHeaderTable>%</TitleHeaderTable>,
          dataIndex: "percent",
          key: "percent",
          render: (text: any, record: any) => (
            <ContentHeaderTable>
               {text}%
            </ContentHeaderTable>
          ),
        },
        {
          title: <TitleHeaderTable>Valor Calculado</TitleHeaderTable>,
          dataIndex: "valueCalculated",
          key: "valueCalculated",
          render: (text: any, record: any) => (
            <ContentHeaderTable>
              {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(text)}
            </ContentHeaderTable>
          ),
        },
        {
          render: (value, record) => (
            <ActionGrid
              handleEdit={handleEdit}
              handleDelete={() => handleDelete({ content: record })}
              roles={{ create: true, edit: true, delete: true, show: true }}
              value={value}
            />
          ),
        },
      ]}
    />
  );
};
