import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Flex, Row } from "antd";
import { Container } from "./style";

export interface IPageHeader {
  title: string;
  onClickAdd?: () => void;
  loading?: boolean;
  children?: React.ReactNode;
  roles: {
    show: boolean;
    create: boolean;
    edit: boolean;
    delete: boolean;
  };
}
export const PageHeader: React.FC<IPageHeader> = ({
  title,
  onClickAdd,
  roles,
  loading,
  children,
}) => {
  return (
    <Row gutter={[8, 2]}>
      <Col span={10}>
        <h1>{title}</h1>
      </Col>
      <Col span={14}>
        <Flex justify="end" gap={2}>
          {roles?.create && (
            <Button
              type="primary"
              onClick={onClickAdd}
              icon={<PlusOutlined />}
              loading={loading}
            >
              Novo
            </Button>
          )}
          {children}
        </Flex>
      </Col>
    </Row>
  );
};
