import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

    :root {

        /* default  */
        --codar-bg-950:#1e1e48;
        --codar-bg-900:#383a86;
        --codar-bg-800:#38389b;
        --codar-bg-700:#4342c0;
        --codar-bg-600:#5152da;
        --codar-bg-500:#6d77e7;
        --codar-bg-400:#8999f0;
        --codar-bg-300:#abbdf6;
        --codar-bg-200:#cbd7fa;
        --codar-bg-100:#e2eafd;
        --codar-bg-50:#eff4fe;

        /* dange */
        --codar-danger-950:#4b0404;
        --codar-danger-900:#881414;
        --codar-danger-800:#a50f0f;
        --codar-danger-700:#c80d0d;
        --codar-danger-600:#e41111;
        --codar-danger-500:#ff3434;
        --codar-danger-400:#ff6464;
        --codar-danger-300:#ff9d9d;
        --codar-danger-200:#ffc5c5;
        --codar-danger-100:#ffdfdf;
        --codar-danger-50:#fff1f1;

        /* warning */
        --codar-warning-950:#471c01;
        --codar-warning-900:#7b390c;
        --codar-warning-800:#96460a;
        --codar-warning-700:#b95b04;
        --codar-warning-600:#e08300;
        --codar-warning-500:#fcac04;
        --codar-warning-400:#ffcd1e;
        --codar-warning-300:#ffdf48;
        --codar-warning-200:#ffee87;
        --codar-warning-100:#fff7c5;
        --codar-warning-50: #fffcea;

        /* success */
        --codar-success-950:#003711;
        --codar-success-900:#076024;
        --codar-success-800:#067528;
        --codar-success-700:#00962d;
        --codar-success-600:#00c035;
        --codar-success-500:#00e043;  
        --codar-success-400:#2bfd6a;
        --codar-success-300:#70ff9c;
        --codar-success-200:#aeffc6;
        --codar-success-100:#d5ffe2;
        --codar-success-50: #edfff2;
    }

    *{
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .ant-layout {
        background:var(--codar-bg-50) !important;
    }
    .ant-layout-footer{
        background:var(--codar-bg-50) !important;
    }
    .ant-layout .ant-layout-sider  {
        background-color:var(--codar-bg-900) !important;
    }
    .ant-menu-dark{
        background-color:var(--codar-bg-900) !important;
    }
    .ant-menu-sub.ant-menu-inline{
        background-color:var(--codar-bg-950) !important;
    }
    .ant-menu-dark.ant-menu-inline {
        background-color:var(--codar-bg-950) !important;
    }
    .ant-menu-dark.ant-menu-submenu-popup>.ant-menu{
        background-color:var(--codar-bg-950) !important;
    }
    .ant-layout .ant-layout-sider-trigger{
        background-color:var(--codar-bg-950) !important;
    }

    /* btn's */
    .ant-btn-default{
        color: var(--codar-bg-500) !important;
        border-color:var(--codar-bg-500) !important;
    }
    .ant-btn-primary {
        background: linear-gradient(var(--codar-bg-700), var(--codar-bg-800));
        color: var(--codar-bg-50) !important;
        border: none;
    }
    .ant-btn-primary:hover {
        background: linear-gradient(
        var(--codar-bg-600),
        var(--codar-bg-700)
        ) !important;
        color: #fff !important;
        border: none !important;
    }
    .ant-transfer-list {
        width: 100% !important;
    }

    .ant-divider{
        margin: 15px !important;
    }
`;
