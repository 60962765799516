import { DatePicker, Typography } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  padding: 24px;

  .bg-geral {
    background-color: var(--codar-bg-50) !important;
  }
  .bg-atms {
    background-color: var(--codar-bg-300) !important;
  }
`;
export const Title = styled(Typography.Text)`
  color: var(--codar-bg-950);
  font-weight: bold;
  font-size: 18px;
`;

export const RangePicker = styled(DatePicker.RangePicker)``;
export const ActionGrid = styled.div`
  display: flex;
  gap: 2px;
`;
