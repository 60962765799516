import { useEffect, useRef, useState } from "react";
import { Container } from "./style";
import BarChart from "../../../components/charts/BarChart";
import { Button, Col, DatePicker, Form, message, Row, Spin } from "antd";
import { api } from "../../../api/api";
import dayjs from "dayjs";
import { CiSearch } from "react-icons/ci";
import { useForm } from "antd/es/form/Form";
import ColumnChart from "../../../components/charts/ColumnChart";

export const CashFlowsAnalytics: React.FC<any> = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{
    receita?: number;
    despesas?: number;
    aPagar?: number;
    aReceber?: number;
    totalGeral?: number;
  }>({});
  const [resumoAnual, setResumoAnual] = useState<any>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [params, setParams] = useState<any>({});
  const [form] = useForm();

  const getData = async (filter?: any) =>
    api.get(`/analytics/cashFlow`, { params: filter });

  const onHandleSearch = () => {
    setLoading(true);
    getData({
      startDate: form.getFieldValue(`date`)?.[0]
        ? dayjs(form.getFieldValue(`date`)?.[0]).format("YYYY-MM-DD")
        : dayjs().startOf("year").format("YYYY-MM-DD"),
      endDate: form.getFieldValue(`date`)?.[1]
        ? dayjs(form.getFieldValue(`date`)?.[1]).format("YYYY-MM-DD")
        : dayjs().endOf("year").format("YYYY-MM-DD"),
    })
      .then(({ data }: any) => {
        const { resumoAnual, ...rest } = data;
        setData(rest);
        setResumoAnual(resumoAnual);
        setLoading(false);
      })
      .catch(() => message.error("Erro ao carregar os dados. Tente novamente"));
  };

  useEffect(() => {
    setLoading(true);
    setCategories([
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ]);
    new Promise((resolve, _) =>
      resolve(
        getData({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        })
          .then(({ data }: any) => {
            const { resumoAnual, ...rest } = data;
            setData(rest);
            setResumoAnual(resumoAnual);
          })
          .catch(() =>
            message.error("Erro ao carregar os dados. Tente novamente")
          )
          .finally(() => setLoading(false))
      )
    );
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Container>
          <Spin tip={"Carregando..."} spinning={loading}>
            <header>
              <Col span={14}>
                <div className="header-title">
                  <h1>Demonstrativo de Fluxo de Caixa</h1>
                </div>
              </Col>
              <Col span={10}>
                <Form form={form} onFinish={onHandleSearch}>
                  <div className="header-filter">
                    <Form.Item name="date">
                      <DatePicker.RangePicker name="date" format="DD/MM/YYYY" />
                    </Form.Item>
                    <Button
                      htmlType="submit"
                      icon={<CiSearch color="#fff" />}
                    />
                  </div>
                </Form>
              </Col>
            </header>

            <Col span={24}>
              <div className="chart-container">
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: 500,
                    color: "#1e1e48",
                  }}
                >
                  Visão Geral Receita X Despesas
                </h3>
                <ColumnChart categories={categories} data={resumoAnual} />
              </div>
            </Col>
            <Col span={24}>
              <i style={{ fontSize: "10px" }}>
                * No fluxo de caixa, são apresentados as receitas e despesas, ou
                seja, as entradas e saídas efetivas de dinheiro que já
                aconteceram no período analisado. O fluxo de caixa reflete o
                movimento real de recursos financeiros, então ele não considera
                contas a pagar ou contas a receber que ainda não foram
                liquidadas.
              </i>
            </Col>
          </Spin>
        </Container>
      </Col>
    </Row>
  );
};
