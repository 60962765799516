import { SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import dayjs from "dayjs";

const { Title } = Typography;

type CreateOrEditForm = {
  onFinish?: (values: any) => void;
  data: any;
};

export const CreateOrEditForm: React.FC<CreateOrEditForm> = ({
  onFinish,
  data,
}) => {
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...data?.employeeDocument,
      employee: data?.employeeDocument?.employee?._id || null,
      asoStartDate: data?.employeeDocument?.asoStartDate
        ? dayjs(data?.employeeDocument?.asoStartDate, "YYYY-MM-DD")
        : null,
      asoEndDate: data?.employeeDocument?.asoEndDate
        ? dayjs(data?.employeeDocument?.asoEndDate, "YYYY-MM-DD")
        : null,
      cnhEndDate: data?.employeeDocument?.cnhEndDate
        ? dayjs(data?.employeeDocument?.cnhEndDate, "YYYY-MM-DD")
        : null,
      epiEndDate: data?.employeeDocument?.epiEndDate
        ? dayjs(data?.employeeDocument?.epiEndDate, "YYYY-MM-DD")
        : null,
    });
  }, [data, form]);

  return (
    <Card bordered={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={[8, 2]}>
          <Col span={24}>
            <Form.Item label="Id" name="_id" hidden>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Colaborador"
              name="employee"
              rules={[{ required: true, message: "Selecione um colaborador" }]}
            >
              <Select
                placeholder="Selecione o colaborador"
                showSearch
                filterOption={(input, option) =>
                  String(option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={data?.employees?.map((e: any) => ({
                  value: e?._id,
                  label: e?.fullname,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Seção ASO */}
        <Card title="ASO" bordered={false} style={{ marginBottom: "16px" }}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="asoStartDate"
                label="Data Exame"
                rules={[{ required: true, message: "Informe a data do exame" }]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format="DD/MM/YYYY"
                  onChange={(date) =>
                    form.setFieldsValue({
                      asoEndDate: dayjs(date).add(1, "year"),
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="asoEndDate" label="Data Vencimento">
                <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        {/* Seção CNH */}
        <Card title="CNH" bordered={false} style={{ marginBottom: "16px" }}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="cnhEndDate" label="Vencimento CNH">
                <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        {/* Seção EPI */}
        <Card title="EPI" bordered={false}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="epiEndDate" label="Vencimento EPI">
                <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        {/* Botão de Salvar */}
        <Row justify="end" style={{ marginTop: "16px" }}>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Salvar
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
