import { Button, Col, Divider, message, Row, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { api } from "../../api/api";
import { ColumnsType } from "antd/es/table";
import Drawer from "../../components/drawer";
import { SearchForm } from "../../forms/accident/SearchForm";
import { CreateOrEditForm } from "../../forms/accident/CreateOrEditForm";
import { PageHeader } from "../../components/page-header";
import { ActionGrid } from "../../components/action-grid";
import { useSecurity } from "../../hooks/useSecurity";
import { DownloadOutlined } from "@ant-design/icons";
import { Container } from "../../components/container";

export const Accident: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [downloadloading, setDownloadloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [accident, setAccident] = useState({});
  const [accidents, setAccidents] = useState<any[]>([]);
  const [roles, setRoles] = useState<any>();

  const { getPermissions } = useSecurity();

  const getData = () => {
    setLoading(true);
    new Promise((resolve, _) => resolve(api.get(`/accidents`)))
      .then(({ data }: any) => setAccidents(data))
      .finally(() => setLoading(false));
  };

  const handleAdd = () => {
    setAction("add");
    setAccident({});
    setOpen(true);
  };

  const handleEdit = ({ data }: any) => {
    setAction("edit");
    setAccident(data);
    setOpen(true);
  };

  const handleDelete = ({ content }: any) => {
    new Promise((resolve, _) =>
      resolve(api.delete(`/accidents/${content?._id}`))
    ).then(() => new Promise((resolve, _) => resolve(getData())));
  };

  const onSubmit = (content: any) => {
    new Promise((resolve, _) => {
      if (content?._id) {
        resolve(api.put(`/accidents/${content?._id}`, content));
      } else {
        resolve(api.post(`/accidents`, content));
      }
    }).then(() => {
      new Promise((resolve, _) => resolve(getData()));
      setAccident({});
      setOpen(false);
      message.success("Registro salvo com sucesso");
    });
  };

  const onSearch = (content: any) => {
    setLoading(true);

    let body = {};
    for (const key in content || {}) {
      if (content[key] !== undefined) {
        body = {
          ...body,
          [key]: content[key],
        };
      }
    }
    const queryString = new URLSearchParams(body).toString();

    if (String(queryString).length !== 0) {
      new Promise((resolve, _) => resolve(api.get(`/accidents?${queryString}`)))
        .then(({ data }: any) => setAccidents(data))
        .finally(() => setLoading(false));
    } else {
      new Promise((resolve, _) => resolve(api.get(`/accidents`)))
        .then(({ data }: any) => setAccidents(data))
        .finally(() => setLoading(false));
    }
  };

  const onDownload = (content: any) => {
    setDownloadloading(true);
    new Promise((resolve, _) => {
      resolve(
        api.get(`/accidents/download/${content?._id}`, { responseType: "blob" })
      );
    }).then(({ data }: any) => {
      
      // Cria um Blob a partir do buffer
    const blob = new Blob([data], { type: 'application/pdf' });

    // Cria um URL para o Blob
    const url = URL.createObjectURL(blob);

    // Cria um link para o download
    const link = document.createElement('a');
    link.href = url;
    link.download = `${content?._id}.pdf`; // Nome do arquivo para o download

    // Simula um clique no link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Libera o URL do Blob
    URL.revokeObjectURL(url);

    }).catch(() => message.error('Falha ao realizar o download. Tente novamente')).finally(() => setDownloadloading(false));
  };

  useEffect(() => {
    new Promise((resolve, _) => resolve(getData()));
  }, []);

  useEffect(() => {
    const role = getPermissions({ route: window.location?.pathname });
    setRoles(role);
  }, [getPermissions]);

  const columns: ColumnsType<[]> = [
    { title: "Número de Controle", dataIndex: "code", key: "code" },
    { title: "Ano", dataIndex: "year", key: "year" },
    {title: "Segurado", dataIndex: "insuredVehicles", key: "insuredVehicles",render: (value) => (value?.flatMap((item: any) => <Tag>{item?.licensePlate}</Tag>))}, 
    {title: "Terceiro", dataIndex: "thirdPartyVehicles", key: "thirdPartyVehicles",render: (value) => (value?.flatMap((item: any) => <Tag>{item?.licensePlate}</Tag>))},
    {
      render: (value, record) => (
        <ActionGrid
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          roles={roles}
          value={value}
        >
          <Button
            loading={downloadloading}
            size="small"
            type="default"
            htmlType="button"
            onClick={()=>onDownload(record)}
            icon={<DownloadOutlined />}
          >
            Download
          </Button>
        </ActionGrid>
      ),
    },
  ];

  return (
    <Container>
      <Row>
        <Col span={24}>
          <PageHeader title="Sinistros" onClickAdd={handleAdd} roles={roles} />
          <SearchForm onFinish={onSearch} />
        </Col>

        <Col span={24}>
          <Divider dashed />
        </Col>
        <Col span={24}>
          <Table
            rowKey={"_id"}
            bordered
            loading={loading}
            dataSource={accidents}
            columns={columns}
            size="small"
            style={{ width: "100%", overflow: "scroll" }}
          />
        </Col>
      </Row>

      <Drawer
        open={open}
        title={action === "add" ? "Adicionar" : "Editar"}
        destroyOnClose={true}
        onClose={() => setOpen(false)}
        children={<CreateOrEditForm onFinish={onSubmit} data={accident} />}
        width={860}
      />
    </Container>
  );
};
