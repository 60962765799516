import { Layout as _Layout } from "antd";
import styled from "styled-components";

export const Layout = styled(_Layout)`
  min-height: 100vh;
`;
export const Content = styled(_Layout.Content)`
 margin:16px;
`;
export const Wrapper = styled.div<{
  colorbgcontainer?: any;
  borderradiuslg?: any;
}>`
  /* padding: 24px; */
  min-height: 360px;
  background-color: ${(prop: any) => prop?.colorbgcontainer};
  border-radius: ${(prop: any) => prop?.borderradiuslg};
`;


