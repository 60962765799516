import { Home } from "../../../pages/home";
import { Users } from "../../../pages/users";
import CashFlow from "../../../pages/cashFlow";
import { Profile } from "../../../pages/profile";
import { Accident } from "../../../pages/accident";
import { Vehicles } from "../../../pages/vehicles";
import { Employees } from "../../../pages/employee";
import { Positions } from "../../../pages/position";
import { Suppliers } from "../../../pages/suppliers";
import { Companies } from "../../../pages/companies";
import { Customers } from "../../../pages/customers";
import { MyProfile } from "../../../pages/myProfile";
import { Categories } from "../../../pages/categories";
import { Associates } from "../../../pages/associates";
import { Parameters } from "../../../pages/parameters";
import AccessDenied from "../../../pages/access-denied";
import { Permissions } from "../../../pages/permission";
import { DocumentTypes } from "../../../pages/documentTypes";
import AccountsPayable from "../../../pages/accountsPayable";
import { VehicleServices } from "../../../pages/vehicleServices";
import { VehicleCategories } from "../../../pages/vehicleCategories";
import { FinancialCategory } from "../../../pages/financialCategories";
import AccountsReceivable from "../../../pages/accountsReceivable/index";
import { EmployeesReport } from "../../../reports/employees/employeesReport";
import { AssociateMonthlyBilling } from "../../../pages/associateMonthlyBilling";
import { EmployeesWithAssociatesReport } from "../../../reports/employees/employeesWithAssociatesReport";
import { PaymentTypes } from "../../../pages/paymentTypes";
import { ReportAssociateMonthlyBilling } from "../../../reports/associateMonthlyBilling/reportAssociateMonthlyBilling";
import { FinancesAnalytics } from "../../../pages/analytics/financesAnalytics";
import { CashFlowsAnalytics } from "../../../pages/analytics/cashFlowAnalitics";
import { FinancialCategoriesAnalytics } from "../../../pages/analytics/financialCategories";
import { EmployeeDocuments } from "../../../pages/employeeDocuments";

export interface Page {
  path: string;
  Element: React.FC;
}

const pageKeys = [
  "home",
  "users",
  "profile",
  "vehicles",
  "cashFlow",
  "services",
  "companies",
  "customers",
  "suppliers",
  "myProfile",
  "associate",
  "positions",
  "employees",
  "accidents",
  "categories",
  "parameters",
  "permissions",
  "access-denied",
  "documentTypes",
  "paymentTypes",
  "accountsPayable",
  "EmployeesReport",
  "vehicleCategories",
  "accountsReceivable",
  "financialCategories",
  "associateMonthlyBilling",
  "EmployeesWithAssociatesReport",
  "reportAssociateMonthlyBilling",
  "financesAnalytics",
  "cashFlowsAnalytics",
  "financialCategoriesAnalytics",
  "employeeDocuments"
] as const;

export type PageKey = (typeof pageKeys)[number];

export const pages: Record<PageKey, Page> = {
  home: {
    path: "/",
    Element: Home,
  },
  companies: {
    path: "/companies",
    Element: Companies,
  },
  users: {
    path: "/users",
    Element: Users,
  },
  categories: {
    path: "/categories",
    Element: Categories,
  },
  "access-denied": {
    path: "/access-denied",
    Element: AccessDenied,
  },
  accountsPayable: {
    path: "/accountsPayable",
    Element: AccountsPayable,
  },
  accountsReceivable: {
    path: "/accountsReceivable",
    Element: AccountsReceivable,
  },
  suppliers: {
    path: "/suppliers",
    Element: Suppliers,
  },
  customers: {
    path: "/customers",
    Element: Customers,
  },
  associate: {
    path: "/associates",
    Element: Associates,
  },
  services: {
    path: "/vehicleServices",
    Element: VehicleServices,
  },
  vehicleCategories: {
    path: "/vehicleCategories",
    Element: VehicleCategories,
  },
  vehicles: {
    path: "/vehicles",
    Element: Vehicles,
  },
  positions: {
    path: "/positions",
    Element: Positions,
  },
  employees: {
    path: "/employees",
    Element: Employees,
  },
  cashFlow: {
    path: "/cashFlow",
    Element: CashFlow,
  },
  financialCategories: {
    path: "/financialCategories",
    Element: FinancialCategory,
  },
  profile: {
    path: "/profile",
    Element: Profile,
  },
  permissions: {
    path: "/permissions",
    Element: Permissions,
  },
  parameters: {
    path: "/parameters",
    Element: Parameters,
  },
  accidents: {
    path: "/accidents",
    Element: Accident,
  },
  associateMonthlyBilling: {
    path: "/associateMonthlyBilling",
    Element: AssociateMonthlyBilling,
  },
  myProfile: {
    path: "/myProfile",
    Element: MyProfile,
  },
  EmployeesReport: {
    path: "/employeesReport",
    Element: EmployeesReport,
  },
  EmployeesWithAssociatesReport: {
    path: "/employeesWithAssociatesReport",
    Element: EmployeesWithAssociatesReport,
  },
  documentTypes: {
    path: "/documentTypes",
    Element: DocumentTypes,
  },
  paymentTypes: {
    path: "/paymentTypes",
    Element: PaymentTypes,
  },
  reportAssociateMonthlyBilling: {
    path: "/reportAssociateMonthlyBilling",
    Element: ReportAssociateMonthlyBilling,
  },
  financesAnalytics: {
    path: "/financesAnalytics",
    Element: FinancesAnalytics,
  },
  cashFlowsAnalytics: {
    path: "/cashFlowsAnalytics",
    Element: CashFlowsAnalytics,
  },
  financialCategoriesAnalytics: {
    path: "/financialCategoriesAnalytics",
    Element: FinancialCategoriesAnalytics,
  },
  employeeDocuments: {
    path: "/employeeDocuments",
    Element: EmployeeDocuments,
  },
};

export const pagesList = Object.keys(pages).map((key) => ({
  ...pages[key as PageKey],
  key,
}));
