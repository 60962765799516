import { Wrapper, Content } from "./style";
import type { MenuProps } from "antd";
import React, { ReactNode, useEffect, useState } from "react";
import { Layout, Menu, theme } from "antd";
import Logo2 from "../../assets/splash.png";
import { RxExit } from "react-icons/rx";
import { useAuth } from "../../hooks/useAuth";
import { application } from "../../constants/application/app";
import { useSecurity } from "../../hooks/useSecurity";
import { FaCircleUser } from "react-icons/fa6";
import { ImProfile } from "react-icons/im";

const { Header, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];


const LayoutRoot: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { destroySession } = useAuth();
  const [collapsed, setCollapsed] = useState(true);
  const [items, setItems] = useState<MenuItem[]>([]);

  const { getDecryptedToken } = useSecurity();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const onLogoff = () => destroySession();

  useEffect(() => {
    const { permissions } = getDecryptedToken();

    const listMenu: any[] = (application as any)?.menu?.reduce((acc: any[], menu: any) => {
      const hasMenuPermission = !!permissions?.find((e: any) => e.route === `/${menu.key}` && e.show);
    
      if (hasMenuPermission) {
        const listSubMenu = (menu.children || []).reduce((subAcc: any[], submenu: any) => {
          const listSubMenu1 = (submenu.children || []).reduce((subSubAcc: any[], element: any) => {
            const hasElementPermission = !!permissions?.find((e: any) => e.route === `/${element.key}` && e.show);
    
            if (hasElementPermission) {
              subSubAcc.push({
                title: element.title,
                label: element.label,
                root: submenu.title,
                page: `${element.title}`,
                route: `/${element.key}`,
              });
            }
    
            return subSubAcc;
          }, []);
    
          // Adiciona o submenu somente se ele tiver permissões ou tiver submenus válidos
          if (listSubMenu1.length > 0 || !!permissions?.find((e: any) => e.route === `/${submenu.key}` && e.show)) {
            subAcc.push({
              ...submenu,
              children: listSubMenu1.length > 0 ? listSubMenu1 : undefined, // Adiciona `children` se houver
            });
          }
    
          return subAcc;
        }, []);
    
        // Adiciona o menu somente se tiver submenus válidos ou se não tiver submenus
        acc.push({
          ...menu,
          children: listSubMenu.length > 0 ? listSubMenu : undefined, // Adiciona `children` se houver
        });
      }
    
      return acc;
    }, []);
    setItems(listMenu);
  }, []);
  
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        width={250}
      >
        <div
          className="demo-logo-vertical"
          style={{
            height: 56,
            margin: 5,
            background: "var(--codar-bg-50)",
            borderRadius: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Logo2}
            style={{
              position: "absolute",
              maxHeight: 56,
            }}
          />
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            textAlign: "end",
          }}
        >
          <Menu
            onClick={() => console.log()}
            selectedKeys={[]}
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: 10,
            }}
            mode="horizontal"
            items={[
              {
                key: "1",
                label: <FaCircleUser style={{ fontSize: 14 }} />,
                children: [
                  {
                    key: "1-1",
                    label: (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <a href="/myProfile" style={{ textDecoration: "none" }}>
                          <ImProfile style={{ marginRight: 10 }} />
                          <span>Perfil</span>
                        </a>
                      </div>
                    ),
                  },
                  {
                    key: "1-2",
                    label: (
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={() => onLogoff()}
                      >
                        <RxExit style={{ marginRight: 10 }} />
                        <span>Sair</span>
                      </div>
                    ),
                  },
                ],
              },
            ]}
          />
        </Header>
        <Content>
          <Wrapper
            borderradiuslg={borderRadiusLG}
            colorbgcontainer={colorBgContainer}
          >
            {children}
          </Wrapper>
        </Content>

        <Footer style={{ textAlign: "center" }}>
          <i>Powered by Codar Sistemas ©{new Date().getFullYear()}</i>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default LayoutRoot;
